<div class="content-area">
  <div class="container">
    <div class="page-title">Upload/Update An Asset</div>
    <div class="row upload-update-selection" *ngIf="!processSelected">
      <div class="col-6">
        <mat-card
          class="elevation"
          (click)="update = true; processSelected = true"
        >
          <span> Update an existing asset </span>
        </mat-card>
      </div>
      <div class="col-6">
        <mat-card
          class="elevation"
          (click)="update = false; processSelected = true"
        >
          <span>Upload a new asset</span>
        </mat-card>
      </div>
    </div>
    <div class="" *ngIf="processSelected">
      <div class="col-12 form">
        <div class="row">
          <!-- FORMS -->

          <!-- UPLOAD ASSET -->
          <form #form="ngForm" method="post">
            <div class="row" *ngIf="!update">
              <mat-form-field class="col-6" appearance="outline">
                <mat-label>Asset Name</mat-label>
                <input
                  name="asset_name"
                  [(ngModel)]="upload_asset.name"
                  matInput
                  required
                />
              </mat-form-field>

              <!-- <mat-form-field
                *ngIf="categories"
                class="col-6"
                appearance="outline"
              >
                <mat-label>Choose a category</mat-label>
                <mat-select
                  name="category"
                  [(ngModel)]="upload_asset.category"
                  required
                >
                  <div *ngFor="let category of categories | categories">
                    <mat-option [value]="category._id">{{
                      category.category
                    }}</mat-option>
                  </div>
                </mat-select>
              </mat-form-field> -->

              <mat-form-field class="col-6" appearance="outline">
                <mat-label>Asset Version *</mat-label>
                <input
                  name="asset_version"
                  [(ngModel)]="upload_asset.latest_version"
                  matInput
                />
              </mat-form-field>

              <!-- <mat-form-field
                *ngIf="categories"
                class="col-6"
                appearance="outline"
              >
                <mat-label>Choose a subcategory</mat-label>
                <mat-select
                  name="subcategory"
                  [disabled]="!upload_asset.category"
                  [(ngModel)]="upload_asset.subcategory"
                  required
                >
                  <div
                    *ngFor="
                      let subcategory of categories
                        | subcategories: upload_asset.category
                    "
                  >
                    <mat-option [value]="subcategory._id">{{
                      subcategory.category
                    }}</mat-option>
                  </div>
                </mat-select>
              </mat-form-field> -->

              <mat-form-field class="col-6" appearance="outline">
                <mat-label>Git url of Asset</mat-label>
                <input
                  name="git_url"
                  [(ngModel)]="upload_asset.git_url"
                  matInput
                  required
                />
              </mat-form-field>

              <mat-form-field class="col-6" appearance="outline">
                <mat-label>Compatible with</mat-label>
                <input
                  name="compatible_with"
                  [(ngModel)]="upload_asset.compatible_with"
                  matInput
                  required
                />
              </mat-form-field>

              <mat-form-field class="col-6" appearance="outline">
                <mat-label>Support *</mat-label>
                <input
                  name="support"
                  [(ngModel)]="upload_asset.support"
                  matInput
                />
              </mat-form-field>

              <mat-form-field class="col-6" appearance="outline">
                <mat-label>Licence *</mat-label>
                <input
                  name="licence"
                  [(ngModel)]="upload_asset.licence"
                  matInput
                />
              </mat-form-field>

              <mat-form-field class="col-6" appearance="outline">
                <mat-label>Price</mat-label>
                <input
                  name="price"
                  type="number"
                  [(ngModel)]="upload_asset.price"
                  matInput
                  required
                />
              </mat-form-field>

              <mat-form-field class="col-6" appearance="outline">
                <mat-label>Description</mat-label>
                <input
                  name="decription"
                  [(ngModel)]="upload_asset.description"
                  matInput
                  required
                />
              </mat-form-field>

              <div *ngIf="all_tags" class="col-12 tag-dropdown">
                <ng-multiselect-dropdown
                  [placeholder]="'Select related tags'"
                  color="primary"
                  [settings]="dropdownSettings"
                  [disabled]="false"
                  [data]="all_tags"
                  [(ngModel)]="selectedTags"
                  (onSelect)="onSelectTag($event)"
                  name="tag"
                >
                </ng-multiselect-dropdown>
              </div>

              <div class="col-12 tag-select-error">
                <span *ngIf="tag_error" class="tag-error"
                  >Please select at least 1 tag. (maximum 6 tags
                  accepted.)</span
                >
              </div>

              <!-- upload image -->
              <div class="image-container col-12">
                <input
                  style="display: none"
                  type="file"
                  (change)="onFileChanged($event, update)"
                  [disabled]="disableImageUpload(imageLoading, update)"
                  multiple
                  #fileInput
                />
                <div class="upload-box" (click)="fileInput.click()">
                  <span *ngIf="!imageLoading"
                    >Upload images here.
                    <span class="information"
                      >(Minimum 1, maximum 6 images accepted)
                    </span>
                    <span
                      [ngClass]="{
                        'full-of-images':
                          upload_asset.images.length >= max_image_number
                      }"
                      class="number-of-images"
                    >
                      {{ upload_asset.images.length }} / {{ max_image_number }}
                    </span>
                  </span>

                  <mat-spinner
                    class="upload-image-spinner"
                    *ngIf="imageLoading"
                    [diameter]="30"
                  ></mat-spinner>
                </div>

                <span class="error-message">
                  {{ image_loading_error }}
                </span>

                <div class="images">
                  <div class="image" *ngFor="let url of upload_asset.images">
                    <img [src]="url" class="rounded mb-3" width="180" />
                    <button
                      type="button"
                      class="btn"
                      (click)="deleteImageUpload(url)"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                  </div>
                </div>
              </div>

              <p class="success">{{ successMessage }}</p>

              <button
                *ngIf="!hideButton"
                name="upload_asset"
                class="button"
                mat-raised-button
                color="primary"
                (click)="uploadAsset()"
                [disabled]="disableButton(form.valid, update)"
              >
                <span *ngIf="!uploadAssetLoading">Upload Asset</span>
                <div class="spinner">
                  <mat-spinner
                    class="upload-asset-spinner"
                    *ngIf="uploadAssetLoading"
                    [diameter]="30"
                  ></mat-spinner>
                </div>
              </button>
            </div>
          </form>

          <!-- UPDATE ASSET -->
          <form #form="ngForm" method="post" *ngIf="assets" style="width: 100%">
            <div class="row" *ngIf="update">
              <mat-form-field class="col-12" appearance="outline">
                <mat-label>Choose an asset</mat-label>
                <mat-select
                  name="asset"
                  [(ngModel)]="update_asset._id"
                  required
                  (selectionChange)="changeUpdatingAsset($event.value)"
                >
                  <div *ngFor="let asset of assets">
                    <mat-option [value]="asset._id">{{
                      asset.name
                    }}</mat-option>
                  </div>
                </mat-select>
              </mat-form-field>

              <div class="col-12" *ngIf="is_asset_selected == true">
                <mat-form-field class="col-6" appearance="outline">
                  <mat-label>Asset Name</mat-label>
                  <input
                    name="asset_name"
                    [(ngModel)]="update_asset.name"
                    matInput
                    required
                  />
                </mat-form-field>

                <!-- <mat-form-field
                  *ngIf="categories"
                  class="col-6"
                  appearance="outline"
                >
                  <mat-label>Choose a category</mat-label>
                  <mat-select
                    name="category"
                    [(ngModel)]="update_asset.category"
                    required
                  >
                    <div *ngFor="let category of categories | categories">
                      <mat-option [value]="category._id">{{
                        category.category
                      }}</mat-option>
                    </div>
                  </mat-select>
                </mat-form-field> -->

                <mat-form-field class="col-6" appearance="outline">
                  <mat-label>Asset Version *</mat-label>
                  <input
                    name="asset_version"
                    [(ngModel)]="update_asset.latest_version"
                    matInput
                  />
                </mat-form-field>

                <!-- <mat-form-field
                  *ngIf="categories"
                  class="col-6"
                  appearance="outline"
                >
                  <mat-label>Choose a subcategory</mat-label>
                  <mat-select
                    name="subcategory"
                    [disabled]="!update_asset.category"
                    [(ngModel)]="update_asset.subcategory"
                    required
                  >
                    <div
                      *ngFor="
                        let subcategory of categories
                          | subcategories: update_asset.category
                      "
                    >
                      <mat-option [value]="subcategory._id">{{
                        subcategory.category
                      }}</mat-option>
                    </div>
                  </mat-select>
                </mat-form-field> -->

                <mat-form-field class="col-6" appearance="outline">
                  <mat-label>Git url of Asset</mat-label>
                  <input
                    name="git_url"
                    [(ngModel)]="update_asset.git_url"
                    matInput
                    required
                  />
                </mat-form-field>

                <mat-form-field class="col-6" appearance="outline">
                  <mat-label>Compatible with</mat-label>
                  <input
                    name="compatible_with"
                    [(ngModel)]="update_asset.compatible_with"
                    matInput
                    required
                  />
                </mat-form-field>

                <mat-form-field class="col-6" appearance="outline">
                  <mat-label>Support *</mat-label>
                  <input
                    name="support"
                    [(ngModel)]="update_asset.support"
                    matInput
                  />
                </mat-form-field>

                <mat-form-field class="col-6" appearance="outline">
                  <mat-label>Licence *</mat-label>
                  <input
                    name="licence"
                    [(ngModel)]="update_asset.licence"
                    matInput
                  />
                </mat-form-field>

                <mat-form-field class="col-6" appearance="outline">
                  <mat-label>Price</mat-label>
                  <input
                    name="price"
                    type="number"
                    [(ngModel)]="update_asset.price"
                    matInput
                    required
                  />
                </mat-form-field>

                <mat-form-field class="col-6" appearance="outline">
                  <mat-label>Description</mat-label>
                  <input
                    name="decription"
                    [(ngModel)]="update_asset.description"
                    matInput
                    required
                  />
                </mat-form-field>

                <div *ngIf="all_tags" class="col-12 tag-dropdown">
                  <ng-multiselect-dropdown
                    [placeholder]="'Select related tags'"
                    color="primary"
                    [settings]="dropdownSettings"
                    [disabled]="false"
                    [data]="all_tags"
                    [(ngModel)]="selectedTags"
                    (onSelect)="onSelectTag($event)"
                    name="tag"
                  >
                  </ng-multiselect-dropdown>
                </div>

                <div class="col-12 tag-select-error">
                  <span *ngIf="tag_error" class="tag-error"
                    >Please select at least 1 tag. (maximum 5 tags
                    accepted.)</span
                  >
                </div>

                <!--update images -->
                <div class="image-container col-12">
                  <input
                    style="display: none"
                    type="file"
                    (change)="onFileChanged($event, update)"
                    [disabled]="disableImageUpload(imageLoading, update)"
                    multiple
                    #fileInput
                  />
                  <div class="upload-box" (click)="fileInput.click()">
                    <span *ngIf="!imageLoading"
                      >Upload images here.
                      <span class="information"
                        >(Minimum 1, maximum 6 images accepted.)
                      </span>
                      <span
                        [ngClass]="{
                          'full-of-images':
                            update_asset.images.length >= max_image_number
                        }"
                        class="number-of-images"
                      >
                        {{ update_asset.images.length }} /
                        {{ max_image_number }}
                      </span>
                    </span>

                    <mat-spinner
                      class="upload-image-spinner"
                      *ngIf="imageLoading"
                      [diameter]="30"
                    ></mat-spinner>
                  </div>

                  <span class="error-message">
                    {{ image_loading_error }}
                  </span>

                  <div class="images">
                    <div class="image" *ngFor="let url of update_asset.images">
                      <img [src]="url" class="rounded mb-3" width="180" />
                      <button
                        type="button"
                        class="btn"
                        (click)="deleteImagesUpdate(url)"
                      >
                        <mat-icon>close</mat-icon>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <p class="success">{{ successMessage }}</p>

              <button
                *ngIf="!hideButton"
                name="upload_asset"
                class="button"
                mat-raised-button
                color="primary"
                (click)="updateAsset()"
                [disabled]="disableButton(form.valid, update)"
              >
                <span *ngIf="!uploadAssetLoading">Update Asset</span>
                <div class="spinner">
                  <mat-spinner
                    class="upload-asset-spinner"
                    *ngIf="uploadAssetLoading"
                    [diameter]="30"
                  ></mat-spinner>
                </div>
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
