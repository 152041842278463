import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Asset } from "src/app/interfaces/data";
import { AssetService } from "src/app/services/api-service/asset-service/asset.service";
import { CategoryService } from "src/app/services/api-service/category-service/category.service";
import { AuthService } from "src/app/services/auth-service/auth.service";
import * as SpicaStorage from "@spica-devkit/storage";
import { IDropdownSettings } from "ng-multiselect-dropdown";
import { TagService } from "src/app/services/api-service/tag-service/tag.service";

@Component({
  selector: "app-author-upload",
  templateUrl: "./author-upload.component.html",
  styleUrls: ["./author-upload.component.scss"],
})
export class AuthorUploadComponent implements OnInit {
  upload_asset: Asset = {
    images: [],
  };
  update_asset: Asset = {
    images: [],
  };
  categories;
  uploadAssetLoading: boolean = false;
  successMessage: string = "";
  hideButton: boolean = false;
  processSelected: boolean = false;
  update: boolean = null;
  imageLoading: boolean = false;
  image_loading_error: string = "";
  assets: Asset[];
  user_id;
  is_asset_selected: boolean = false;

  disable_image_upload: boolean = false;
  max_image_number = 6;

  // TAG DROPDWON
  dropdownSettings: IDropdownSettings = {};
  selectedTags = [];
  all_tags;
  tag_error: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private assetService: AssetService,
    private categoryService: CategoryService,
    private authService: AuthService,
    private tagService: TagService
  ) {
    this.user_id = this.authService.get_user_id();
    this.getCategories();
    this.getAssets();

    SpicaStorage.initialize({
      apikey: "eqoa1kcujy27g",
      publicUrl: "https://assetstore-882ef.hq.spicaengine.com/api",
    });
  }

  ngOnInit(): void {
    this.assignTags();

    this.dropdownSettings = {
      singleSelection: false,
      idField: "_id",
      textField: "tag",
      enableCheckAll: false,
      itemsShowLimit: 6,
      maxHeight: 197,
      allowSearchFilter: true,
      searchPlaceholderText: "search tag",
      limitSelection: 5,
      closeDropDownOnSelection: false,
      showSelectedItemsAtTop: false,
      defaultOpen: false,
    };
  }

  async assignTags() {
    this.all_tags = await this.tagService.getTags();
  }

  onFileChanged(event, is_update) {
    let all_are_image = true;
    const files = event.target.files;

    if (files.length > 0 && files) {
      this.imageLoadErrorMessage("");
      this.imageLoading = true;

      Array.prototype.forEach.call(files, (file) => {
        if (file.type.match(/image\/*/) == null) {
          this.imageLoading = false;
          all_are_image = false;
          this.imageLoadErrorMessage("Only images are supported.");
          return;
        }
      });

      if (all_are_image == true) {
        SpicaStorage.insertMany(files)
          .then((data) => {
            data.forEach((respond) => {
              is_update
                ? this.addImageUpdate(respond.url)
                : this.addImagesUpload(respond.url);
            });
            this.imageLoading = false;
          })
          .catch((error) => {
            this.image_loading_error =
              "Error! Error happened while uploading images.";
            console.log("error while adding storage", error);
          });
      }
    }
  }

  // UPLOAD ASSET FUNCTION
  updateUploadedAsset(asset, user_id: string) {
    asset.user = user_id;
    asset.tags = this.getArrayOfTagId(this.selectedTags);
    return asset;
  }

  uploadAsset() {
    if (this.validNumberOfTags()) {
      this.hideTagError();
      this.uploadAssetLoading = true;
      this.assetService
        .uploadAsset(this.updateUploadedAsset(this.upload_asset, this.user_id))
        .toPromise()
        .then((data) => {
          if (data) {
            this.uploadAssetLoading = false;
            this.successMessage = "Uploading a new asset is successful.";
            this.hideButton = true;
            setTimeout(() => {
              this.successMessage = "";
              this.router.navigate([`/author`], { relativeTo: this.route });
            }, 2000);
          }
        })
        .catch((error) => {});
    } else {
      this.showTagError();
    }
  }

  addImagesUpload(url) {
    this.upload_asset.images.push(url);
  }

  deleteImageUpload(url) {
    this.imageLoading = true;
    this.upload_asset.images = this.upload_asset.images.filter(function (
      value
    ) {
      return url != value;
    });
    this.imageLoading = false;
  }

  // UPDATE ASSET FUNCTION
  changeUpdatingAsset(asset_id) {
    this.is_asset_selected = true;
    let asset = this.getAssetWithId(asset_id);
    this.update_asset = {
      ...asset,
      user: asset.user._id,
    };
    this.selectedTags = this.update_asset.tags;
  }

  updateAsset() {
    if (this.validNumberOfTags()) {
      this.update_asset.tags = this.getArrayOfTagId(this.selectedTags);

      this.hideTagError();
      this.uploadAssetLoading = true;
      this.assetService
        .updateAsset(this.update_asset)
        .toPromise()
        .then((data) => {
          if (data) {
            this.uploadAssetLoading = false;
            this.successMessage = "Updating an asset is successful.";
            this.hideButton = true;
            setTimeout(() => {
              this.successMessage = "";
              this.router.navigate([`/author`], { relativeTo: this.route });
            }, 2000);
          }
        })
        .catch((error) => {});
    } else {
      this.showTagError();
    }
  }

  addImageUpdate(url) {
    this.update_asset.images.push(url);
  }

  deleteImagesUpdate(url) {
    this.update_asset.images = this.update_asset.images.filter(function (
      value
    ) {
      return url != value;
    });
  }

  // HELPER or COMMON FUNCTION

  disableButton(formValid, update) {
    if (
      formValid == true && this.imageLoading == false && update == true
        ? this.update_asset.images.length > 0 &&
          this.update_asset.images.length <= this.max_image_number
        : this.upload_asset.images.length > 0 &&
          this.upload_asset.images.length <= this.max_image_number
    ) {
      return false;
    } else {
      return true;
    }
  }

  disableImageUpload(imageLoading, update) {
    if (imageLoading == true) {
      return true;
    } else if (
      update == true
        ? this.update_asset.images.length >= this.max_image_number
        : this.upload_asset.images.length >= this.max_image_number
    ) {
      return true;
    } else {
      return false;
    }
  }

  imageLoadErrorMessage(error: string) {
    this.image_loading_error = error;
  }

  getAssetWithId(asset_id) {
    for (let asset of this.assets) {
      if (asset._id == asset_id) {
        return asset;
      }
    }
  }

  validNumberOfTags() {
    return this.selectedTags.length > 0;
  }

  getArrayOfTagId(tags) {
    let new_tag_array = [];

    tags.forEach((tag) => {
      new_tag_array.push(tag._id);
    });

    return new_tag_array;
  }

  showTagError() {
    this.tag_error = true;
  }

  hideTagError() {
    this.tag_error = false;
  }

  getCategories() {
    this.categoryService
      .getAllCategoryInformation()
      .toPromise()
      .then((data) => {
        this.categories = data;
      });
  }

  async getAssets() {
    this.assetService
      .getAssetofAuthor(this.user_id)
      .toPromise()
      .then((data) => {
        this.assets = data;
      });
  }

  checkFilesAreImage(files): boolean {
    Array.prototype.forEach.call(files, (file) => {
      if (file.type.match(/image\/*/) == null) {
        return false;
      }
    });
    return true;
  }

  onSelectTag(event) {}
}
