import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "developer-card",
  templateUrl: "./developer-card.component.html",
  styleUrls: ["./developer-card.component.scss"],
})
export class DeveloperCardComponent implements OnInit {
  @Input() thumbnail;
  @Input() developer_name;
  @Input() email;
  @Input() website;
  @Input() address;
  @Input() description;

  constructor() {}

  ngOnInit(): void {}
}
