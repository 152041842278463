import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Asset, User } from "src/app/interfaces/data";
import { AssetService } from "src/app/services/api-service/asset-service/asset.service";
import { UserService } from "src/app/services/api-service/user-service/user.service";

@Component({
  selector: "app-developer-page",
  templateUrl: "./developer-page.component.html",
  styleUrls: ["./developer-page.component.scss"],
})
export class DeveloperPageComponent implements OnInit {
  user_id;
  user: User;
  assets: Asset[];

  constructor(
    private route: ActivatedRoute,
    private assetService: AssetService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((data) => {
      this.user_id = data.author_id;
      this.getAuthor(this.user_id);
      this.getAssetsOfAuthor(this.user_id);
    });
  }

  getAuthor(user_id: string) {
    this.userService.getAuthorInformation(user_id).subscribe((data) => {
      this.user = data[0];
    });
  }

  getAssetsOfAuthor(user_id: string) {
    this.assetService.getAssetofAuthor(user_id).subscribe((data) => {
      this.assets = data;
    });
  }
}
