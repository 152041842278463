import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Filter, Market } from "src/app/interfaces/data";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { UrlService } from "../url-service/url.service";

@Injectable({
  providedIn: "root",
})
export class MarketService {
  constructor(private apiService: ApiService, private urlService: UrlService) {}

  //GET FUNCTIONS
  getBoughtAssets(user_id: string, filter?: Filter) {
    let filterJson: Filter = {};
    let max_price = filter ? filter.max_price : "";
    let publish_date = filter ? filter.publish_date : "";
    let publisher = filter ? filter.publisher : "";

    filterJson["user._id"] = user_id;
    filterJson.status = "sold";

    if (max_price > 0 && max_price) {
      max_price = max_price + 0.0000001;
      filterJson["asset.price"] = { $lt: max_price };
    }

    if (publish_date != "" && publish_date) {
      filterJson["asset.latest_release_date"] = {
        $gte: `Date(${publish_date})`,
      };
    }

    if (publisher != "" && publisher) {
      filterJson["user.author.developer_name"] = {
        $regex: `${publisher}`,
        $options: "si",
      };
    }

    const url = this.urlService.bucketUrl(environment.bucket.market);

    const params = {
      relation: "true",
      filter: JSON.stringify(filterJson),
    };

    return this.apiService.get<Market[]>(url, params);
  }

  getUserCartAssets(user_id: string) {
    const url = this.urlService.bucketUrl(environment.bucket.market);

    const params = {
      relation: "true",
      filter: JSON.stringify({
        "user._id": user_id,
        status: "onCart",
      }),
    };

    return this.apiService.get<Market[]>(url, params);
  }

  //UPDATE FUNCTIONS
  buyAsset(market: Market) {
    const url = this.urlService.bucketUrlWithId(
      environment.bucket.market,
      market._id
    );

    const data = {
      _id: market._id,
      user: market.user._id,
      status: "sold",
      asset: market.asset._id,
      project_id: market.project_id,
    };

    return this.apiService.put(url, data);
  }

  //POST FUNCTIONS
  addAssetToCart(user_id: string, asset_id: string, project_id: string) {
    const url = this.urlService.bucketUrl(environment.bucket.market);

    const data = {
      user: `${user_id}`,
      asset: `${asset_id}`,
      project_id: `${project_id}`,
    };

    return this.apiService.post(url, data);
  }

  getMarketWithId(market_id) {
    const url = this.urlService.bucketUrl(environment.bucket.market);

    const params = {
      relation: "true",
      filter: JSON.stringify({
        _id: market_id,
      }),
    };

    return this.apiService.get(url, params);
  }

  getBoughtAssetWithId(asset_id, user_id): Observable<Market[]> {
    const url = this.urlService.bucketUrl(environment.bucket.market);

    const params = {
      relation: "true",
      filter: JSON.stringify({
        "user._id": user_id,
        "asset._id": asset_id,
      }),
    };

    return this.apiService.get<Market[]>(url, params);
  }

  //DELETE FUNCTIONS
  deleteAssetFromCart(market_id: string) {
    const url = this.urlService.bucketUrlWithId(
      environment.bucket.market,
      market_id
    );

    return this.apiService.delete(url);
  }
}
