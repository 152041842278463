<div class="content-area container">
  <div class="row">
    <div class="container spinner" *ngIf="isProcessing">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>

    <div class="col-9 item-list" *ngIf="!isProcessing">
      <div
        class="container"
        *ngIf="markets.length == 0"
        style="display: flex; align-items: center; justify-content: center"
      >
        <a style="font-size: 1.5rem">You don`t have any asset in your cart.</a>
      </div>
      <!-- ----------------------------- -->
      <mat-card *ngFor="let market of markets">
        <div class="row item">
          <div
            class="col-2"
            [routerLink]="['/item-detail/', market.asset['_id']]"
          >
            <img src="{{ market.asset.images[0] }}" />
          </div>
          <div
            class="col-7"
            [routerLink]="['/item-detail/', market.asset['_id']]"
          >
            <div class="title">{{ market.asset.name }}</div>
            <div class="description">
              {{ market.asset.description }}
            </div>
          </div>
          <div class="col-2">
            <div class="price spica-text-primary">
              ${{ market.asset.price }}
            </div>
          </div>
          <div class="col-1">
            <button mat-icon-button color="warn">
              <mat-icon (click)="deleteFromCart(market._id)">delete</mat-icon>
            </button>
          </div>
        </div>
      </mat-card>
      <!-- ----------------------------- -->
    </div>
    <div class="col-3 sticky" *ngIf="!isProcessing">
      <!-- ----------------------------- -->
      <mat-card>
        <div class="last-check">
          <div class="total">
            <span>Total Amount</span><span>{{ calculateTotal() }} USD</span>
          </div>

          <div class="item" *ngFor="let market of markets">
            <span>{{ market.asset.name }}</span>
            <span>{{ market.asset.price }} USD</span>
          </div>
          <mat-divider></mat-divider>
          <button
            class="col-12"
            mat-button
            mat-raised-button
            color="primary"
            [disabled]="!(markets.length > 0)"
            (click)="checkout()"
          >
            Checkout
          </button>
        </div>
      </mat-card>
      <!-- ----------------------------- -->
    </div>
  </div>
</div>
