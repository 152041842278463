<div class="content-area container">
  <div class="row">
    <asset-integration-dialog
      *ngIf="assetLoading"
      [step]="3"
      [asset_integrate_successful]="asset_integrate_successful"
      [asset_integrate_error]="integration_error_occur"
    >
    </asset-integration-dialog>
    <div class="col-9 container spinner" *ngIf="!markets">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <div class="col-9 item-list" *ngIf="markets">
      <div
        class="container"
        *ngIf="markets.length == 0"
        style="display: flex; align-items: center; justify-content: center"
      >
        <a style="font-size: 1.5rem">You didn`t buy any asset, yet.</a>
      </div>

      <mat-card *ngFor="let market of markets">
        <div class="item">
          <img
            [routerLink]="['/item-detail/', market.asset['_id']]"
            src="{{ market.asset.images[0] }}"
          />
          <div
            class="info"
            [routerLink]="['/item-detail/', market.asset['_id']]"
          >
            <div class="title">
              {{ market.asset.name }}
            </div>
            <div class="purchase-date">
              Purchased on: {{ market.date | date }}
            </div>
            <div class="release-note">
              Version: {{ market.asset.latest_version }}
            </div>
            <div class="description">
              {{ market.asset.description }}
            </div>
          </div>
          <div class="actions">
            <generic-rating
              [rating]="market.asset.rating_average"
              [ratingCount]="market.asset.rating_count"
            ></generic-rating>
            <button
              [disabled]="assetLoading"
              class="suffix-button button-positive"
              mat-button
              color="primary"
              (click)="openIntegrateAssetDialog(market)"
            >
              Select Project
            </button>
          </div>
        </div>
      </mat-card>
    </div>
    <div class="col-3">
      <detailed-search (filter)="setFilter($event)"></detailed-search>
    </div>
  </div>
</div>
