import { Component, OnInit } from "@angular/core";
import { Asset } from "src/app/interfaces/data";
import { AssetService } from "src/app/services/api-service/asset-service/asset.service";
import { UserService } from "src/app/services/api-service/user-service/user.service";
import { AuthService } from "src/app/services/auth-service/auth.service";
import * as SpicaStorage from "@spica-devkit/storage";

@Component({
  selector: "app-author-page",
  templateUrl: "./author-page.component.html",
  styleUrls: ["./author-page.component.scss"],
})
export class AuthorPageComponent implements OnInit {
  user_id: string;

  assets: Asset[];

  author;

  isProcessing_author: boolean = false;
  isProcessing_assets: boolean = false;

  thumbnail_error: string = "";
  thumbnail_loading: boolean = false;

  constructor(
    private assetService: AssetService,
    private userService: UserService,
    private authService: AuthService
  ) {
    SpicaStorage.initialize({
      apikey: "eqoa1kcujy27g",
      publicUrl: "https://assetstore-882ef.hq.spicaengine.com/api",
    });
  }

  ngOnInit(): void {
    this.getAllData();
  }

  getAllData() {
    this.user_id = this.authService.get_user_id();
    this.getAuthorInformation(this.user_id);
    this.getAuthorAssets(this.user_id);
  }

  getAuthorInformation(user_id: string) {
    this.userService
      .getAuthorInformation(user_id)
      .toPromise()
      .then((data) => {
        this.author = data[0].author;
      });
  }

  getAuthorAssets(user_id: string) {
    this.assetService
      .getOwnAssets(user_id)
      .toPromise()
      .then((data) => {
        this.assets = data;
        this.isProcessing_author = false;
        this.isProcessing_assets = false;
      });
  }

  saveAuthorInformation() {
    this.isProcessing_author = true;
    this.userService
      .updateAuthorInformation(this.user_id, this.author)
      .toPromise()
      .then((data) => {
        this.getAllData();
      });
  }

  deleteAsset(asset_id: string) {
    this.isProcessing_assets = true;
    this.assetService
      .deleteAsset(asset_id)
      .toPromise()
      .then((data) => {
        this.getAllData();
      });
  }

  onFileChanged(event) {
    let is_image = true;
    let file;
    const files = event.target.files;

    if (files.length > 0 && files) {
      this.thumbnail_loading = true;
      this.thumbnail_error = "";
      Array.prototype.forEach.call(files, (file) => {
        if (file.type.match(/image\/*/) == null) {
          this.thumbnail_loading = false;
          is_image = false;
          this.thumbnail_error = "Only images are supported.";
          return;
        }
      });

      if (is_image == true) {
        file = files[0];
        SpicaStorage.insert(file).then((data) => {
          this.thumbnail_loading = false;
          this.author.thumbnail = data[0].url;
        });
      }
    }
  }
}
