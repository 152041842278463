<div class="rating">
  <div class="star" *ngFor="let i of [0, 1, 2, 3, 4]">
    <mat-icon *ngIf="rating - i > 0.5">star</mat-icon>
    <mat-icon *ngIf="rating - i <= 0.5 && rating - i > 0">star_half</mat-icon>
    <mat-icon *ngIf="rating - i <= 0">star_border</mat-icon>
  </div>

  <div class="rating-count" *ngIf="ratingCount != null">
    ( {{ ratingCount }} )
  </div>
</div>
