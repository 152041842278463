import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ApiService } from "src/app/services/api-service/api.service";
import { tap, switchMap } from "rxjs/operators";
import { Asset, Review } from "src/app/interfaces/data";
import { AssetService } from "src/app/services/api-service/asset-service/asset.service";
import { MarketService } from "src/app/services/api-service/market-service/market.service";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { MatDialog } from "@angular/material/dialog";
import { ServerSelectionDiolagComponent } from "src/app/components/server-selection-diolag/server-selection-diolag.component";
import { UploadAssetSpinnerService } from "src/app/services/ui-service/upload-asset-spinner/upload-asset-spinner.service";
import { Subscription } from "rxjs";
import { ChangeDetectorRef } from "@angular/core";
import { IntegrationService } from "src/app/services/api-service/integration-service/integration.service";

@Component({
  selector: "app-asset-detail",
  templateUrl: "./asset-detail.component.html",
  styleUrls: ["./asset-detail.component.scss"],
})
export class AssetDetailComponent implements OnInit {
  user_id: string;

  asset_id: string = "";
  asset: Asset;
  reviews: Review[];
  ratingCount: number;
  ratingMean: number;
  subAssets: Asset[];
  selectedProject: string;

  selected_image: string;

  asset_integrate_loading: boolean = false;
  asset_integrate_loading_subscription: Subscription;

  asset_integrate_successful: boolean = false;

  integrate_button_messages: object = {
    not_bought: "Get FREE and Integrate",
    in_cart: "Checkout and Integrate",
    bought: "Integrate",
  };

  integrate_button_text: string = "";

  checkout_messages: object = {
    not_bought: "Add to Cart",
    in_cart: "You added to cart.",
    bought: "You bought this asset.",
  };
  checkout_message_text: string = "";

  asset_has_bought: boolean;
  in_cart: boolean;
  asset_on_market;

  // integration dialog
  integration_step = 0;
  integration_error_occur: boolean = false;

  constructor(
    private route: ActivatedRoute,
    private assetService: AssetService,
    private router: Router,
    private apiService: ApiService,
    private marketService: MarketService,
    private authService: AuthService,
    private dialog: MatDialog,
    private uploadAssetSpinnerService: UploadAssetSpinnerService,
    private cdRef: ChangeDetectorRef,
    private integrationService: IntegrationService
  ) {}

  ngOnInit(): void {
    this.init();

    this.route.params.subscribe((data) => {
      this.asset_id = data.asset_id;
      this.ratingCount = 0;
      this.ratingMean = 0;
      this.getAsset(this.asset_id);
      this.getReviews(this.asset_id);
      this.getUserId();
    });
  }

  init() {
    this.asset_integrate_loading_subscription = this.uploadAssetSpinnerService
      .getSpinner()
      .subscribe((state) => {
        this.asset_integrate_loading = state;
        this.cdRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.asset_integrate_loading_subscription.unsubscribe();
  }

  getUserId() {
    this.user_id = this.authService.get_user_id();
  }

  getAsset(asset_id: string) {
    this.assetService
      .getSingleAsset(asset_id)
      .pipe(
        tap((data) => {
          this.asset = data[0];
          this.selected_image = this.asset.images[0];
          this.checkPurchaseOperation(this.asset._id, this.user_id);
        }),
        switchMap(() => this.assetService.getSimilarAssets(this.asset_id, 2))
      )
      .subscribe((data) => {
        this.subAssets = data;
      });
  }

  getReviews(asset_id: string) {
    this.apiService.getReviews(asset_id, true).subscribe((data) => {
      this.reviews = data;
      this.reviews.length > 0
        ? this.calculateReviewMean(this.reviews)
        : console;
    });
  }

  addToCartAndNavigate() {
    this.asset = undefined;
    this.marketService
      .addAssetToCart(this.user_id, this.asset_id, this.selectedProject)
      .subscribe((data) => {
        this.router.navigate([`/cart`], { relativeTo: this.route });
      });
  }

  calculateReviewMean(tempReviews: Review[]) {
    this.ratingCount = tempReviews.length;

    for (let review of tempReviews) {
      this.ratingMean = this.ratingMean + review["score"];
    }

    this.ratingMean = this.ratingMean / tempReviews.length;
  }

  checkPurchaseOperation(asset_id, user_id) {
    this.marketService
      .getBoughtAssetWithId(asset_id, user_id)
      .toPromise()
      .then((data) => {
        if (data.length > 0) {
          this.asset_on_market = data[0];
          this.asset_has_bought = true;

          if (data[0].status == "onCart") {
            this.integrate_button_text =
              this.integrate_button_messages["in_cart"];
            this.checkout_message_text = this.checkout_messages["in_cart"];
            this.in_cart = true;
          } else {
            this.integrate_button_text =
              this.integrate_button_messages["bought"];
            this.checkout_message_text = this.checkout_messages["bought"];
            this.in_cart = false;
          }
        } else {
          this.integrate_button_text =
            this.integrate_button_messages["not_bought"];
          this.checkout_message_text = this.checkout_messages["not_bought"];
          this.asset_has_bought = false;
        }
      });
  }

  openIntegrateAssetDialog() {
    this.integrationService.openIntegrationAssetDialog(this.asset_id);
  }
}
