import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "subcategories",
})
export class SubcategoriesPipe implements PipeTransform {
  transform(allList: any, categoryId: any): any {
    return allList.filter((category) => category.parent_category == categoryId);
  }
}
