<div class="content-area">
  <div class="container">
    <div class="page-title">Author Page</div>

    <div class="container spinner" *ngIf="!author || isProcessing_author">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>

    <div *ngIf="!isProcessing_author">
      <div class="row" *ngIf="author">
        <mat-form-field class="col-4" appearance="outline">
          <mat-label>Developer Name *</mat-label>
          <input [(ngModel)]="author.developer_name" matInput />
        </mat-form-field>

        <mat-form-field class="col-4" appearance="outline">
          <mat-label>Email *</mat-label>
          <input [(ngModel)]="author.email" matInput />
        </mat-form-field>

        <mat-form-field class="col-4" appearance="outline">
          <mat-label>Website *</mat-label>
          <input [(ngModel)]="author.website" matInput />
        </mat-form-field>

        <div class="col-2">
          <div class="image">
            <img src="{{ author.thumbnail }}" alt="thumbnail" />
          </div>
        </div>

        <div class="col-3">
          <input
            style="display: none"
            type="file"
            (change)="onFileChanged($event)"
            #fileInput
          />

          <mat-card
            class="upload-image no-elevation"
            (click)="fileInput.click()"
          >
            <div>
              <div class="upload-text">
                Upload new thumbnail Image
                <br />
                <span>{{ thumbnail_error }}</span>
                <mat-spinner
                  *ngIf="thumbnail_loading"
                  [diameter]="35"
                ></mat-spinner>
                <mat-icon *ngIf="!thumbnail_loading">cloud_upload</mat-icon>
              </div>
            </div>
          </mat-card>
        </div>

        <mat-form-field class="col-7 address" appearance="outline">
          <mat-label>Address</mat-label>
          <textarea [(ngModel)]="author.address" matInput></textarea>
        </mat-form-field>

        <mat-form-field class="col-12 description" appearance="outline">
          <mat-label>Description</mat-label>
          <textarea [(ngModel)]="author.description" matInput></textarea>
        </mat-form-field>
      </div>
      <div class="action-buttons">
        <button (click)="saveAuthorInformation()" color="primary" mat-button>
          Save
        </button>
      </div>
    </div>

    <!--- ASSETS of Author--->
    <div class="page-title">Uploaded assets</div>

    <div class="container spinner" *ngIf="!assets || isProcessing_assets">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>

    <div *ngIf="!isProcessing_assets">
      <div class="row" *ngIf="assets">
        <div
          class="container"
          *ngIf="assets.length == 0"
          style="display: flex; align-items: center; justify-content: center"
        >
          <a style="font-size: 1.5rem">You don`t have any uploaded asset.</a>
        </div>

        <div class="col-12">
          <div class="row" *ngIf="assets">
            <div class="col-4 asset-card" *ngFor="let asset of assets">
              <item-single
                [images]="asset['images']"
                [name]="asset['name']"
                [price]="asset['price']"
                [sales]="asset['sales']"
                [user_id]="asset['user']['_id']"
                [asset_id]="asset['_id']"
                [author]="asset.user.author.developer_name"
                [integrated_count]="asset['integrated_count']"
                [routerLink]="['/item-detail/', asset['_id']]"
                [show_integrate]="false"
              ></item-single>

              <div
                class="delete-container"
                matTooltip="Delete asset permanently"
                style="text-align: center"
              >
                <button class="delete-button" mat-raised-button color="warn">
                  <a (click)="deleteAsset(asset._id)">
                    <mat-icon style="color: white">delete</mat-icon>
                  </a>
                </button>
              </div>
              <div
                class="approve-message"
                style="text-align: center"
                *ngIf="!!!asset.approved"
              >
                <span>Not approved yet.</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
