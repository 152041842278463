import { Injectable } from "@angular/core";
import { Category } from "src/app/interfaces/data";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { UrlService } from "../url-service/url.service";

@Injectable({
  providedIn: "root",
})
export class CategoryService {
  constructor(private apiService: ApiService, private urlService: UrlService) {}

  //GET FUNCTIONS
  getCategories() {
    const url = this.urlService.bucketUrl(environment.bucket.category);

    let params = {
      filter: JSON.stringify({
        parent_category: null,
      }),
    };

    return this.apiService.get<Category[]>(url, params);
  }

  getAllCategoryInformation() {
    const url = this.urlService.bucketUrl(environment.bucket.category);

    return this.apiService.get<Category[]>(url, {});
  }

  getSubCategories(categoryId: string) {
    const url = this.urlService.bucketUrl(environment.bucket.category);

    const params = {
      filter: JSON.stringify({
        parent_category: categoryId,
      }),
    };

    return this.apiService.get<Category[]>(url, params);
  }
}
