import { Injectable } from "@angular/core";
import { Tag } from "src/app/interfaces/data";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { UrlService } from "../url-service/url.service";

@Injectable({
  providedIn: "root",
})
export class TagService {
  tags: Tag[];

  constructor(private apiService: ApiService, private urlService: UrlService) {}

  async getTags() {
    if (this.tags?.length > 0) {
      return this.tags;
    } else {
      return await  this.getTagsFromServer()
        .toPromise()
        .then((data) => {
          if (data) {
            this.tags = data;
            return this.tags;
          } else {
            throw Error("Error while catching tags.");
          }
        })
        .catch((error) => {
          throw Error(error);
        });
    }
  }

  private getTagsFromServer() {
    const url = this.urlService.bucketUrl(environment.bucket.tags);

    return this.apiService.get<Tag[]>(url, {});
  }
}
