import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { switchMap, tap } from "rxjs/operators";
import { ServerSelectionDiolagComponent } from "src/app/components/server-selection-diolag/server-selection-diolag.component";
import { PurchaseInformation } from "src/app/interfaces/data";
import { AuthService } from "../../auth-service/auth.service";
import { IntegrateAssetStepperService } from "../../ui-service/integrate-asset-stepper/integrate-asset-stepper.service";
import { UploadAssetSpinnerService } from "../../ui-service/upload-asset-spinner/upload-asset-spinner.service";
import { AssetService } from "../asset-service/asset.service";
import { MarketService } from "../market-service/market.service";

@Injectable({
  providedIn: "root",
})
export class IntegrationService {
  constructor(
    private authService: AuthService,
    private marketService: MarketService,
    private dialog: MatDialog,
    private assetService: AssetService,
    private uploadAssetSpinnerService: UploadAssetSpinnerService,
    private integrateAssetStepper: IntegrateAssetStepperService
  ) {}

  user_id: string;

  openIntegrationAssetDialog(asset_id) {
    const dialogRef = this.dialog.open(ServerSelectionDiolagComponent, {
      width: "600px",
      data: {},
    });

    dialogRef.afterClosed().subscribe((server_id) => {
      if (server_id != undefined) {
        this.startIntegration(asset_id, server_id);
      }
    });
  }

  async startIntegration(asset_id, server_id) {
    this.user_id = this.authService.get_user_id();

    if (this.user_id) {
      let purchaseInformation: PurchaseInformation =
        await this.checkPurchaseOperation(asset_id, this.user_id);

      if (purchaseInformation.in_market == true) {
        if (purchaseInformation.on_cart == true) {
          // checkout and integration
          this.checkoutAndIntegrate(server_id, purchaseInformation);
        } else if (purchaseInformation.on_cart == false) {
          // just integrate
          this.integrate(server_id, purchaseInformation);
        }
      } else if (purchaseInformation.in_market == false) {
        // get and integrate
        this.getAndIntegrate(server_id, this.user_id, asset_id);
      }
    }
  }

  moveNextStep() {
    this.integrateAssetStepper.moveNextStep();
  }

  // CHECKING PURCHASE OPERATION - ON_CART - IN_MARKET
  checkPurchaseOperation(asset_id, user_id): Promise<PurchaseInformation> {
    this.uploadAssetSpinnerService.start();
    let message: PurchaseInformation = {
      on_cart: undefined,
      in_market: undefined,
      asset_on_market: undefined,
    };

    return new Promise((resolve, reject) => {
      this.marketService
        .getBoughtAssetWithId(asset_id, user_id)
        .toPromise()
        .then((data) => {
          // asset is in market of this user
          if (data.length > 0) {
            message.asset_on_market = data[0];

            message.in_market = true;

            if (data[0].status == "onCart") {
              message.on_cart = true;
            } else if (data[0].status == "sold") {
              message.on_cart = false;
            }

            resolve(message);
          }
          // user neither didn't buy nor add cart
          else {
            message.in_market = false;
            resolve(message);
          }
        })
        .catch((error) => {
          reject(new Error(error));
        });
    });
  }

  // -- INTEGRATION TYPES
  integrate(server_id, purchaseInformation) {
    this.moveNextStep();
    this.moveNextStep();
    this.moveNextStep();

    this.assetService
      .integrateAsset(purchaseInformation.asset_on_market, server_id, true)
      .toPromise()
      .then((data) => {
        this.moveNextStep();

        this.intergrationDone();
      })
      .catch((error) => {
        this.integrationError();
      });
  }

  checkoutAndIntegrate(server_id, purchaseInformation) {
    this.marketService
      .buyAsset(purchaseInformation.asset_on_market)
      .pipe(
        tap(() => {
          this.moveNextStep();
          this.moveNextStep();
          this.moveNextStep();
        }),
        switchMap(() =>
          this.assetService.integrateAsset(
            purchaseInformation.asset_on_market,
            server_id,
            true
          )
        ),
        tap(() => {
          this.moveNextStep();
        })
      )
      .subscribe(
        (data) => {
          this.intergrationDone();
        },
        (error) => {
          this.integrationError();
        }
      );
  }

  getAndIntegrate(server_id, user_id, asset_id) {
    let market_data;
    let market_data_with_relation;

    this.addToCart(user_id, asset_id)
      .pipe(
        tap((data) => {
          market_data = data;
          this.moveNextStep();
        }),
        switchMap(() => this.getMarket(market_data["_id"])),
        tap((data) => {
          market_data_with_relation = data[0];
          this.moveNextStep();
        }),
        switchMap(() => this.marketService.buyAsset(market_data_with_relation)),
        tap((data) => {
          this.moveNextStep();
        }),
        switchMap(() =>
          this.assetService.integrateAsset(
            market_data_with_relation,
            server_id,
            true
          )
        )
      )
      .subscribe(
        (data) => {
          this.intergrationDone();
        },
        (error) => {
          this.integrationError();
        }
      );
  }

  addToCart(user_id, asset_id) {
    let selectedProject;

    return this.marketService.addAssetToCart(
      user_id,
      asset_id,
      selectedProject
    );
  }

  getMarket(market_id) {
    return this.marketService.getMarketWithId(market_id);
  }

  // STATES
  intergrationDone() {
    this.integrateAssetStepper.done();

    setTimeout(() => {
      this.integrateAssetStepper.reset();
      this.uploadAssetSpinnerService.stop();
      this.restartPage();
    }, 3000);
  }

  integrationError() {
    this.integrateAssetStepper.error();

    setTimeout(() => {
      this.integrateAssetStepper.reset();
      this.uploadAssetSpinnerService.stop();
      this.restartPage();
    }, 3000);
  }

  restartPage() {
    window.location.reload();
  }
}
