<div class="content-area container">
  <div class="row">
    <div class="col-8">
      <div class="container spinner" *ngIf="asset_loading">
        <mat-spinner [diameter]="35"></mat-spinner>
      </div>

      <div class="tags" *ngIf="tags.length > 0 && !asset_loading">
        <div
          class="selected-tag"
          *ngFor="let tag of tags.split('\\')"
          (click)="removeTagsFromList(tag)"
        >
          <tag class="overlay" [tag]="tag"></tag>
          <div class="remove-icon overlay">remove</div>
        </div>
      </div>

      <div class="row" *ngIf="!asset_loading">
        <div
          class="container"
          *ngIf="allAssets.length == 0"
          style="display: flex; align-items: center; justify-content: center"
        >
          <a style="font-size: 1.5rem">We couldn't find any results. </a>
        </div>

        <div class="col-6" *ngFor="let asset of allAssets">
          <item-single
            [images]="asset['images']"
            [name]="asset['name']"
            [price]="asset['price']"
            [sales]="asset['sales']"
            [user_id]="asset['user']['_id']"
            [asset_id]="asset['_id']"
            [author]="asset.user.author.developer_name"
            [integrated_count]="asset['integrated_count']"
            [rating_count]="asset.rating_count"
            [rating_average]="asset.rating_average"
            [show_tags]="true"
            [tags]="asset.tags"
            (event)="filterWithTags($event)"
          ></item-single>
        </div>
      </div>
    </div>
    <div class="col-4">
      <detailed-search (filter)="setFilter($event)"> </detailed-search>
    </div>
  </div>
</div>
