<div class="search-container">
  <div class="heading">
    <span>Refine By</span>
    <a
      mat-button
      (click)="cleanFilter()"
      style="color: red"
      matTooltip="Clear filter"
      >Clear filter</a
    >
  </div>
  <div class="filter-section">
    <h3 class="title">Pricing</h3>
    <div class="filter-item">
      <mat-slider
        [(ngModel)]="max_price"
        name="max_price"
        min="0"
        max="1000"
        step="1"
        value="{{ max_price }}"
        [thumbLabel]="true"
        matTooltip="Set price manually"
      ></mat-slider>
    </div>
    <div class="filter-item">
      <mat-form-field appearance="outline" matTooltip="Enter max price">
        <mat-label>Max Amount</mat-label>
        <input matInput type="number" required [(ngModel)]="max_price" />
      </mat-form-field>
    </div>
  </div>
  <div class="filter-section">
    <h3 class="title">Publish Date</h3>

    <mat-radio-group
      [(ngModel)]="filter_publish_date"
      matTooltip="Set publish date"
    >
      <div class="filter-item">
        <mat-radio-button value="7">Last 7 Days</mat-radio-button>
      </div>
      <div class="filter-item">
        <mat-radio-button value="30">Last 30 Days</mat-radio-button>
      </div>
      <div class="filter-item">
        <mat-radio-button value="90">Last 90 Days</mat-radio-button>
      </div>
      <div class="filter-item">
        <mat-radio-button value="180">Last 6 Months</mat-radio-button>
      </div>
      <div class="filter-item">
        <mat-radio-button value="365">Last 1 Year</mat-radio-button>
      </div>
    </mat-radio-group>
  </div>
  <div class="filter-section">
    <h3 class="title">Publisher</h3>
    <div class="filter-item">
      <mat-form-field appearance="outline">
        <mat-label>Publisher</mat-label>
        <input
          matInput
          [(ngModel)]="publisher"
          matTooltip="Publisher of Asset"
        />
      </mat-form-field>
    </div>
  </div>
  <div class="filter-section">
    <div class="filter-item">
      <button
        mat-raised-button
        color="primary"
        style="width: 500px"
        (click)="filterSave()"
        matTooltip="Filter Assets"
      >
        Filter
      </button>
    </div>
  </div>
</div>
