<mat-card class="developer-card">
  <div class="avatar">
    <img src="{{ thumbnail }}" />
  </div>
  <div class="title">{{ developer_name }}</div>
  <div class="email">{{ email }}</div>
  <a class="website" href="{{ website }}">{{ website }}</a>
  <div class="address">{{ address }}</div>
  <hr class="solid" />
  <div class="description">{{ description }}</div>
</mat-card>
