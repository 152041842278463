<div class="content-area">
  <div *ngIf="!asIframe" class="featured-content container">
    <img
      src="https://images.unsplash.com/photo-1498050108023-c5249f4df085?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1352&q=80"
    />
  </div>
  <div class="container">
    <div class="spinner" *ngIf="!featuredAssets">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>
    <div class="items">
      <div class="item-featured row" *ngIf="featuredAssets && !asIframe">
        <div
          *ngFor="let asset of featuredAssets"
          [ngClass]="{
            'left col-8': featuredAssets.indexOf(asset) % 2 == 0,
            'right col-4': featuredAssets.indexOf(asset) % 2 == 1
          }"
        >
          <item-featured
            class="single-item-featured"
            [images]="asset['images']"
            [name]="asset['name']"
            [price]="asset['price']"
            [sales]="asset['sales']"
            [asset_id]="asset['_id']"
            [author]="asset.user.author.developer_name"
            [routerLink]="['/item-detail/', asset['_id']]"
          ></item-featured>
        </div>
      </div>

      <div class="item-list row" *ngIf="assets">
        <div class="col-lg-4 col-sm-12" *ngFor="let asset of assets">
          <item-single
            style="margin-bottom: 25px; cursor: pointer"
            [images]="asset['images']"
            [name]="asset['name']"
            [price]="asset['price']"
            [sales]="asset['sales']"
            [user_id]="asset['user']['_id']"
            [asset_id]="asset['_id']"
            [author]="asset.user.author.developer_name"
            [integrated_count]="asset['integrated_count']"
            [rating_count]="asset.rating_count"
            [rating_average]="asset.rating_average"
          ></item-single>
        </div>
      </div>
    </div>
  </div>
</div>
