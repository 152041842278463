import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";
import { Review } from "src/app/interfaces/data";
import { UrlService } from "./url-service/url.service";

@Injectable({
  providedIn: "root",
})
export class ApiService {
  constructor(private http: HttpClient, private urlService: UrlService) {}

  getReviews(asset_id: String, relation: boolean) {
    const url = this.urlService.bucketUrl(environment.bucket.reviews);

    const params = {
      filter: JSON.stringify(
        relation ? { "asset._id": asset_id } : { asset: asset_id }
      ),
      relation: String(relation),
    };

    return this.get<Review[]>(url, params);
  }

  //helper functions
  post<T>(url, data) {
    return this.http.post<T>(url, data);
  }

  get<T>(url, params) {
    return this.http.get<T>(url, { params });
  }

  put<T>(url, data) {
    return this.http.put<T>(url, data);
  }

  patch<T>(url, data) {
    return this.http.patch<T>(url, data);
  }

  delete(url) {
    return this.http.delete(url);
  }
}
