import { Component, OnInit } from "@angular/core";
import { Asset } from "src/app/interfaces/data";
import { AssetService } from "src/app/services/api-service/asset-service/asset.service";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  assets: Asset[];
  featuredAssets: Asset[];
  asIframe: boolean = true;
  constructor(private assetService: AssetService) {}

  ngOnInit(): void {
    this.getFeatureAssets();
    this.getAssets();

    var isInIFrame = window.location != window.parent.location;
    if (!isInIFrame) {
      this.asIframe = false;
    }
  }

  getFeatureAssets() {
    this.assetService
      .getFeaturedAssets()
      .toPromise()
      .then((data) => {
        this.featuredAssets = data;
      });
  }

  getAssets() {
    this.assetService
      .getAssets()
      .toPromise()
      .then((data) => {
        this.assets = data;
      });
  }
}
