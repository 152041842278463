<div class="product-container">
  <div class="asset-image">
    <img class="image" [src]="thumbnail_image" />

    <div class="overlay"></div>
  </div>

  <div class="meta-data">
    <div class="name">{{ name }}</div>
    <div class="author">
      by <span class="author-name">{{ author }}</span>
    </div>
    <div class="price">{{ price | currency: currency }}</div>
    <generic-rating
      [rating]="rating"
      [ratingCount]="ratingCount"
    ></generic-rating>

    <div class="sales">{{ sales }} Sales</div>
  </div>
</div>
