import { SimpleChanges } from "@angular/core";
import { Input } from "@angular/core";
import { Component, OnInit } from "@angular/core";

@Component({
  selector: "asset-integration-dialog",
  templateUrl: "./asset-integration-dialog.component.html",
  styleUrls: ["./asset-integration-dialog.component.scss"],
})
export class AssetIntegrationDialogComponent implements OnInit {
  constructor() {}

  @Input() step: number = 0;
  @Input() asset_integrate_successful: boolean = false;
  @Input() asset_integrate_error: boolean = false;

  waiting_message: string = "Please wait...";
  integrate_message: string = "checking operation";
  completed_steps: number = 0;

  integration_loading_messages = [
    "adding to cart",
    "getting asset for free",
    "integrating to server",
  ];

  ngOnInit(): void {
    setTimeout(() => {
      this.waiting_message = "Don't break your screen yet!";

      setTimeout(() => {
        this.waiting_message = "I swear it's almost done.";

        setTimeout(() => {
          this.waiting_message = "Patience! This is difficult, you know...";
        }, 5000);
      }, 5000);
    }, 5000);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.moveNextStep(this.step);
  }

  moveNextStep(step) {
    this.integrate_message = this.integration_loading_messages[step - 1];
    this.completed_steps = step;
  }
}
