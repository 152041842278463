import { Component, OnInit, Input } from "@angular/core";
import { Review } from "src/app/interfaces/data";
import { ApiService } from "src/app/services/api-service/api.service";

@Component({
  selector: "item-featured",
  templateUrl: "./item-featured.component.html",
  styleUrls: ["./item-featured.component.scss"],
})
export class ItemFeaturedComponent implements OnInit {
  @Input() images: any[];
  @Input() name: string;
  @Input() asset_id: string;
  @Input() price: number;
  @Input() author: string;
  @Input() sales: number;
  @Input() currency: string = "USD";

  allReviews: Review[];
  rating: number = 0;
  ratingCount: number;
  thumbnail_image: string;

  constructor(private apiService: ApiService) {}

  ngOnInit(): void {
    this.thumbnail_image = this.images[0];
  }

  getReviewInformation() {
    this.apiService.getReviews(this.asset_id, false).subscribe((data) => {
      this.allReviews = data;

      this.ratingCount = this.allReviews.length;

      for (let review of this.allReviews) {
        this.rating += review["score"];
      }
      this.rating = this.rating / this.allReviews.length;
    });
  }
}
