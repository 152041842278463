import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-server-selection-diolag",
  templateUrl: "./server-selection-diolag.component.html",
  styleUrls: ["./server-selection-diolag.component.scss"],
})
export class ServerSelectionDiolagComponent implements OnInit {
  constructor(public diolagRef: MatDialogRef<ServerSelectionDiolagComponent>) {}

  ngOnInit(): void {}

  selectProject(event) {
    this.diolagRef.close(event.detail._id);
  }
}
