import { Component, OnInit } from "@angular/core";
import { Subject } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { Filter, Market, User } from "src/app/interfaces/data";
import { MarketService } from "src/app/services/api-service/market-service/market.service";
import { AuthService } from "src/app/services/auth-service/auth.service";
import { IntegrationService } from "src/app/services/api-service/integration-service/integration.service";

@Component({
  selector: "app-my-assets",
  templateUrl: "./my-assets.component.html",
  styleUrls: ["./my-assets.component.scss"],
})
export class MyAssetsComponent implements OnInit {
  user_id: string;

  user: User;
  markets: Market;
  public filter: Filter = {};
  refresh = new Subject<void>();
  assetLoading: boolean = false;

  asset_integrate_successful: boolean = false;
  integration_error_occur: boolean = false;

  constructor(
    public dialog: MatDialog,
    private marketService: MarketService,
    private authService: AuthService,
    private integrationService: IntegrationService
  ) {}

  ngOnInit(): void {
    this.user_id = this.authService.get_user_id();

    this.marketService
      .getBoughtAssets(this.user_id, this.filter)
      .toPromise()
      .then((data) => {
        this.markets = data;
      });
  }

  setFilter(filter) {
    this.filter = filter;
    this.refresh.next();
  }

  openIntegrateAssetDialog(market) {
    this.integrationService.openIntegrationAssetDialog(market.asset._id);
  }
}
