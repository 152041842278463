import { Component, OnInit, Output, EventEmitter } from "@angular/core";
import { Filter } from "src/app/interfaces/data";

@Component({
  selector: "detailed-search",
  templateUrl: "./detailed-search.component.html",
  styleUrls: ["./detailed-search.component.scss"],
})
export class DetailedSearchComponent implements OnInit {
  filter_publish_date: number;
  date: Date;

  max_price: number = 0;
  publish_date: string = "";
  publisher: string = "";

  @Output() filter = new EventEmitter<Filter>();

  constructor() {}

  ngOnInit(): void {
    this.filter.emit({
      max_price: 0,
      publish_date: "",
      publisher: "",
    });
  }

  filterSave() {
    this.filter.emit({
      max_price: this.max_price == undefined ? 0 : this.max_price,
      publish_date: this.getDate(this.filter_publish_date),
      publisher: this.publisher,
    });
  }

  cleanFilter() {
    this.max_price = 0;
    this.publish_date = undefined;
    this.filter_publish_date = undefined;
    this.publisher = undefined;
  }

  getDate(filter_publish_date: number) {
    if (filter_publish_date) {
      this.date = new Date(
        Date.now() - 60 * 60 * 24 * filter_publish_date * 1000
      );
      return this.date.toISOString();
    } else {
      return "";
    }
  }
}
