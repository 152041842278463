import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";
import { ApiService } from "../api-service/api.service";
import * as jwt_decode from "jwt-decode";
import { UrlService } from "../api-service/url-service/url.service";
import { environment } from "src/environments/environment";
import { User } from "src/app/interfaces/data";
import { Subject } from "rxjs";

@Injectable()
export class AuthService {
  constructor(
    public jwtHelper: JwtHelperService,
    private apiService: ApiService,
    private urlService: UrlService
  ) {}

  hq_token;
  assetstore_token;
  user_id;
  identity_id;
  user_onchange: Subject<string> = new Subject();

  set_hq_token(hq_token) {
    this.hq_token = hq_token;
    localStorage.setItem("hq_token", hq_token);
    this.get_assetstore_token();
  }

  set_assetstore_token(assetstore_token) {
    this.assetstore_token = assetstore_token;
    localStorage.setItem("assetstore_token", assetstore_token);
  }

  set_user_id(user_id) {
    this.user_id = user_id;
    this.user_onchange.next(this.user_id);
    if (!user_id) {
      localStorage.removeItem("user_id");
    } else {
      localStorage.setItem("user_id", user_id);
    }
  }

  get_hq_token() {
    if (this.hq_token) {
      return this.hq_token;
    } else {
      return localStorage.getItem("hq_token");
    }
  }

  get_assetstore_token() {
    if (localStorage.getItem("hq_token")) {
      if (localStorage.getItem(`assetstore_token`)) {
        return localStorage.getItem(`assetstore_token`);
      } else {
        this.assignAssetstoreToken(localStorage.getItem("hq_token")).then(
          (data) => {
            if (data == true) {
              this.get_user_id();
              return this.assetstore_token;
            } else if (data == false) {
              console.log("error while assiging assetstore token");
            } else {
              console.log("unknown error");
            }
          }
        );
      }
    }
  }

  get_user_id() {
    if (localStorage.getItem("user_id")) {
      return localStorage.getItem("user_id");
    } else {
      this.assignUserId();
    }
  }

  get_identity_id() {
    return jwt_decode(localStorage.getItem("assetstore_token"))._id;
  }

  public isAuthenticated(): boolean {
    return (
      !!localStorage.getItem("hq_token") &&
      !!localStorage.getItem("assetstore_token")
    );
  }

  register(hq_token) {
    const url = this.urlService.functionUrl(environment.function.register);

    return this.apiService.post<any>(url, { token: hq_token });
  }

  login(hq_token) {
    const url = this.urlService.functionUrl(environment.function.login);

    return this.apiService.post<any>(url, { token: hq_token });
  }

  assignUserId() {
    this.getUserFromBucket(this.get_identity_id())
      .toPromise()
      .then((data) => {
        if (data[0]) {
          this.set_user_id(data[0]._id);
        }
      });
  }

  assignAssetstoreToken(hq_token) {
    return new Promise((resolve, reject) => {
      this.login(hq_token)
        .toPromise()
        .then((data) => {
          this.assetstore_token = data.token;
          this.set_assetstore_token(this.assetstore_token);
          resolve(true);
        })
        .catch((error) => {
          console.log("error ", error);
          reject(new Error(error));
        });
    });
  }

  deleteLocalStorage() {
    localStorage.clear();
    window.location.reload();
  }

  getUserFromBucket(identity_id) {
    const url = this.urlService.bucketUrl(environment.bucket.user);

    const params = {
      filter: JSON.stringify({
        identity_id: identity_id,
      }),
    };
    return this.apiService.get<User[]>(url, params);
  }
}
