import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { AuthService } from "../auth-service/auth.service";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = {
      Authorization: "APIKEY " + environment.api_key,
    };

    if (localStorage.getItem("assetstore_token")) {
      headers["authorization"] =
        "IDENTITY " + this.authService.get_assetstore_token();
    }

    request = request.clone({
      setHeaders: headers,
    });
    return next.handle(request);
  }
}
