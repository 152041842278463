import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class UrlService {
  constructor() {}

  bucketUrl(bucket_id) {
    return environment.url + "/bucket/" + bucket_id + "/data/";
  }

  bucketUrlWithId(bucket_id, id) {
    return environment.url + "/bucket/" + bucket_id + "/data/" + id;
  }

  functionUrl(function_name) {
    return environment.url + "/fn-execute/" + function_name;
  }
}
