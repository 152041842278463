<div class="overlay-container">
  <div class="message-container">
    <div class="content" *ngIf="!asset_integrate_error">
      <mat-spinner diameter="30" *ngIf="!asset_integrate_successful">
      </mat-spinner>

      <div class="success-icon" *ngIf="asset_integrate_successful">
        <svg
          class="ft-green-tick"
          xmlns="http://www.w3.org/2000/svg"
          height="100"
          width="100"
          viewBox="0 0 48 48"
          aria-hidden="true"
        >
          <circle class="circle" fill="#5bb543" cx="24" cy="24" r="22" />
          <path
            class="tick"
            fill="none"
            stroke="#FFF"
            stroke-width="4"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-miterlimit="10"
            d="M14 27l5.917 4.917L34 17"
          />
        </svg>
      </div>

      <br />

      <div class="loading-message" *ngIf="!asset_integrate_successful">
        <span class="wait-text">{{ waiting_message }}</span>
        <br />
        <br />
        <span class="integrating-text"
          >step {{ completed_steps }}/3 - {{ integrate_message }}</span
        >
      </div>

      <div class="success-message" *ngIf="asset_integrate_successful">
        <span>Asset successfully integrated.</span>
      </div>
    </div>

    <div class="error" *ngIf="asset_integrate_error">
      <div class="sa">
        <div class="sa-error">
          <div class="sa-error-x">
            <div class="sa-error-left"></div>
            <div class="sa-error-right"></div>
          </div>
          <div class="sa-error-placeholder"></div>
          <div class="sa-error-fix"></div>
        </div>
      </div>

      <span>Error occurs. Try it later, please.</span>
    </div>
  </div>
</div>
