import { NgModule } from '@angular/core';
import { HomeComponent } from './pages/home/home.component';
import { AssetDetailComponent } from './pages/asset-detail/asset-detail.component';
import { AssetListComponent } from './pages/asset-list/asset-list.component';
import { DeveloperPageComponent } from './pages/developer-page/developer-page.component';
import { MyAssetsComponent } from './pages/my-assets/my-assets.component';
import { CartComponent } from './pages/cart/cart.component';
import { AuthorPageComponent } from './pages/author-page/author-page.component';
import { AuthorUploadComponent } from './pages/author-upload/author-upload.component';

import { AuthGuardService as AuthGuard } from './services/auth-service/auth-guard.service';
import { Routes, RouterModule} from '@angular/router';


const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'item-detail/:asset_id', component: AssetDetailComponent},
  { path: 'item-list', component: AssetListComponent },
  { path: 'item-list/:category', component: AssetListComponent },
  { path: 'item-list/:category/:subcategory', component: AssetListComponent },
  { path: 'developer/:author_id', component: DeveloperPageComponent },
  { path: 'assets', component: MyAssetsComponent, canActivate: [AuthGuard] },
  { path: 'cart', component: CartComponent, canActivate: [AuthGuard] },
  { path: 'author', component: AuthorPageComponent, canActivate: [AuthGuard] },
  { path: 'upload', component: AuthorUploadComponent, canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
