import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { IntegratingAssetInformation } from "src/app/interfaces/data";

@Injectable({
  providedIn: "root",
})
export class IntegrateAssetStepperService {
  private message: IntegratingAssetInformation = {
    step: 0,
    state: "loading",
  };

  private stepper$ = new BehaviorSubject<IntegratingAssetInformation>({
    step: 0,
    state: undefined,
  });

  constructor() {}

  getStepper() {
    return this.stepper$.asObservable();
  }

  moveNextStep() {
    this.message.step += 1;

    this.stepper$.next(this.message);
  }

  movePreviousStep() {
    if (this.message.step <= 1) {
      this.message.step = 0;
    } else {
      this.message.step -= 1;
    }

    this.stepper$.next(this.message);
  }

  done() {
    this.message.state = "success";
    this.stepper$.next(this.message);
  }

  error() {
    this.message.state = "error";
    this.stepper$.next(this.message);
  }

  reset() {
    this.message.step = undefined;
    this.message.state = undefined;
    this.stepper$.next(this.message);
  }
}
