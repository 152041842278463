import { BrowserModule } from "@angular/platform-browser";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { HomeComponent } from "./pages/home/home.component";
import { AssetListComponent } from "./pages/asset-list/asset-list.component";
import { AssetDetailComponent } from "./pages/asset-detail/asset-detail.component";
import { DeveloperPageComponent } from "./pages/developer-page/developer-page.component";
import { ItemSingleComponent } from "./components/item-single/item-single.component";
import { DetailedSearchComponent } from "./components/detailed-search/detailed-search.component";
import { DeveloperCardComponent } from "./components/developer-card/developer-card.component";
import { FormsModule } from "@angular/forms";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from "@angular/material/tooltip";

import { MatIconModule } from "@angular/material/icon";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatSliderModule } from "@angular/material/slider";
import { MatInputModule } from "@angular/material/input";
import { MatRadioModule } from "@angular/material/radio";
import { ItemFeaturedComponent } from "./components/item-featured/item-featured.component";
import { MatToolbarModule } from "@angular/material/toolbar";
import { MatButtonModule } from "@angular/material/button";
import { UserCommentComponent } from "./components/user-comment/user-comment.component";
import { GenericRatingComponent } from "./components/generic-rating/generic-rating.component";
import { MatCardModule } from "@angular/material/card";
import { MyAssetsComponent } from "./pages/my-assets/my-assets.component";
import { CartComponent } from "./pages/cart/cart.component";
import { MatDividerModule } from "@angular/material/divider";
import { MatMenuModule } from "@angular/material/menu";
import { AuthorPageComponent } from "./pages/author-page/author-page.component";
import { AuthorUploadComponent } from "./pages/author-upload/author-upload.component";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { TokenInterceptor } from "./services/api-service/interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { HttpClientModule } from "@angular/common/http";
import { AuthGuardService } from "./services/auth-service/auth-guard.service";
import { AuthService } from "./services/auth-service/auth.service";
import { JwtModule } from "@auth0/angular-jwt";
import { JwtHelperService, JWT_OPTIONS } from "@auth0/angular-jwt";
import { SubcategoriesPipe } from "./pipes/subcategories.pipe";
import { CategoriesPipe } from "./pipes/categories.pipe";
import { ServerSelectionDiolagComponent } from "./components/server-selection-diolag/server-selection-diolag.component";
import { TagComponent } from "./components/tag/tag.component";
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { AssetIntegrationDialogComponent } from "./components/asset-integration-dialog/asset-integration-dialog.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AssetListComponent,
    AssetDetailComponent,
    DeveloperPageComponent,
    ItemSingleComponent,
    DetailedSearchComponent,
    DeveloperCardComponent,
    ItemFeaturedComponent,
    UserCommentComponent,
    GenericRatingComponent,
    MyAssetsComponent,
    CartComponent,
    AuthorPageComponent,
    AuthorUploadComponent,
    SubcategoriesPipe,
    CategoriesPipe,
    ServerSelectionDiolagComponent,
    TagComponent,
    AssetIntegrationDialogComponent,
  ],
  imports: [
    MatProgressSpinnerModule,
    JwtModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    MatButtonModule,
    MatIconModule,
    MatSliderModule,
    MatCheckboxModule,
    MatInputModule,
    MatRadioModule,
    MatToolbarModule,
    MatCardModule,
    MatDividerModule,
    MatMenuModule,
    MatSelectModule,
    BrowserAnimationsModule,
    MatAutocompleteModule,
    MatDialogModule,
    MatTooltipModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  providers: [
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    JwtHelperService,
    AuthGuardService,
    AuthService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
