<div class="content-area container">
  <div class="row">
    <div class="col-3" *ngIf="user">
      <developer-card
        [thumbnail]="user.author.thumbnail"
        [developer_name]="user.author.developer_name"
        [website]="user.author.website"
        [description]="user.author.description"
        [email]="user.author.email"
        [address]="user.author.address"
      ></developer-card>
    </div>

    <div class="container spinner" *ngIf="!assets">
      <mat-spinner [diameter]="35"></mat-spinner>
    </div>

    <div class="col-9 item-list" *ngIf="assets">
      <div class="row">
        <div class="col-4 item" *ngFor="let asset of assets">
          <item-single
            [images]="asset['images']"
            [name]="asset['name']"
            [price]="asset['price']"
            [sales]="asset['sales']"
            [user_id]="asset['user']['_id']"
            [asset_id]="asset['_id']"
            [author]="asset.user.author.developer_name"
            [integrated_count]="asset['integrated_count']"
            [routerLink]="['/item-detail', asset['_id']]"
          ></item-single>
        </div>
      </div>
    </div>
  </div>
</div>
