<div class="comment-container">
  <div class="user">
    <div class="avatar"></div>
    <div class="name">{{ user_name }}</div>
  </div>
  <div class="comment">
    <div class="rating">
      <generic-rating [rating]="score" [ratingCount]="null"></generic-rating>
    </div>
    <div class="comment">
      {{ text }}
    </div>
  </div>
</div>
