import { Injectable } from "@angular/core";
import { User } from "src/app/interfaces/data";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { UrlService } from "../url-service/url.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  currentUser: any;
  constructor(private apiService: ApiService, private urlService: UrlService) {}

  //GET FUNCTIONS
  getAuthorInformation(user_id: string) {
    const url = this.urlService.bucketUrl(environment.bucket.user);

    const params = {
      filter: JSON.stringify({
        _id: user_id,
      }),
    };

    return this.apiService.get<User[]>(url, params);
  }

  getSearchedDevelopers(searchText: string) {
    const url = this.urlService.bucketUrl(environment.bucket.user);

    const params = {
      filter: JSON.stringify({
        "author.developer_name": {
          $regex: `${searchText}`,
          $options: "si",
        },
      }),
      limit: "3",
    };

    return this.apiService.get<User[]>(url, params);
  }

  //UPDATE FUNCTIONS
  updateAuthorInformation(user_id, author) {
    const url = this.urlService.bucketUrlWithId(
      environment.bucket.user,
      user_id
    );

    const data = {
      author: author,
    };

    return this.apiService.patch(url, data);
  }
}
