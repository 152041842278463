<div class="item-container">
  <div
    class="product-container"
    [routerLink]="['/item-detail', asset_id]"
    [class.horizontal]="horizontal"
  >
    <img class="image" [src]="thumbnail_image" />

    <div class="meta-data">
      <div class="name">{{ name }}</div>
      <div class="author" matTooltip="Developer">
        by <a [routerLink]="['/developer', user_id]">{{ author }}</a>
      </div>
      <div class="price">
        <span class="price-text">{{ price | currency: currency }}</span>

        <!-- <generic-rating
          [rating]="rating_average"
          [ratingCount]="rating_count"
        ></generic-rating> -->

        <span
          class="download-count"
          matTooltip="
          {{ integrated_count == 0 ? 'Never integrated before' : undefined }}
          {{ integrated_count == 1 ? 'Integrated once before' : undefined }}
          {{
            integrated_count > 1
              ? 'Integrated ' + integrated_count + '  times in total'
              : undefined
          }}"
          >{{ integrated_count }}<mat-icon>download</mat-icon></span
        >
      </div>
    </div>
  </div>

  <div
    class="integrate-container"
    *ngIf="show_integrate"
    matTooltip="Fast integration"
    (click)="openDialog()"
  >
    <span class="integrate-button"
      ><mat-icon>download</mat-icon> &nbsp; integrate</span
    >
  </div>

  <div class="tags-container" *ngIf="show_tags">
    <div class="tag" *ngFor="let tag of tags" (click)="tagSelected(tag)">
      <tag [tag]="tag.tag"></tag>
    </div>
  </div>
</div>
