<div class="content-area">
  <!-- asset_integrate_loading -->
  <!-- asset_integrate_successful -->

  <asset-integration-dialog
    *ngIf="asset_integrate_loading"
    [step]="integration_step"
    [asset_integrate_successful]="asset_integrate_successful"
    [asset_integrate_error]="integration_error_occur"
  >
  </asset-integration-dialog>

  <div class="spinner" *ngIf="!asset">
    <mat-spinner [diameter]="35"></mat-spinner>
  </div>
  <div class="container" *ngIf="asset">
    <div class="row">
      <div class="asset-details col-8">
        <div class="featured-image-parent">
          <img class="featured-image" src="{{ selected_image }}" />
        </div>
        <div class="row image-parent">
          <img
            *ngFor="let image of asset.images"
            (click)="selected_image = image"
            class="image"
            src="{{ image }}"
          />
        </div>
        <div class="description">
          <h3 class="title">Description</h3>
          <div>
            {{ asset.description }}
          </div>
        </div>

        <div *ngIf="reviews">
          <div class="asset-meta" *ngFor="let review of reviews">
            <user-comment
              class="featured-comment"
              [date]="review['date']"
              [score]="review['score']"
              [text]="review['text']"
              [user_name]="review.user.author['developer_name']"
            ></user-comment>
          </div>
        </div>
      </div>
      <div class="asset-meta col-4">
        <div class="row">
          <div class="col-6 package-name">{{ asset.name }}</div>
          <div class="col-6 rating" *ngIf="ratingMean && ratingCount">
            <generic-rating
              [rating]="asset.rating_average"
              [ratingCount]="asset.rating_count"
            ></generic-rating>
          </div>
          <div class="col-12">
            <div class="price spica-text-primary">
              {{ asset.price | currency: "USD" }}
            </div>
            <span>Taxes/VAT calculated at checkout</span>
          </div>
          <div class="col-12 refund">
            <mat-icon color="primary">repeat</mat-icon>
            Refunds - We've got you covered
          </div>

          <div class="col-12" *ngIf="user_id">
            <button
              *ngIf="!asset_has_bought"
              class="col-12 button-positive"
              (click)="addToCartAndNavigate()"
              mat-button
              color="primary"
            >
              {{ checkout_message_text }}
            </button>
            <span class="checkout-message" *ngIf="asset_has_bought"
              >{{ checkout_message_text }}

              <span *ngIf="in_cart">
                <a [routerLink]="['/cart']">Go to your cart.</a>
              </span>

              <span *ngIf="!in_cart">
                <a [routerLink]="['/assets']">Go to your assets.</a>
              </span>
            </span>
          </div>

          <div class="or-text" *ngIf="user_id && asset.price <= 0">
            <span>OR</span>
          </div>

          <div class="col-12" *ngIf="user_id && asset.price <= 0">
            <button
              class="col-12 button-positive"
              (click)="openIntegrateAssetDialog()"
              mat-button
              color="primary"
            >
              {{ integrate_button_text }}
            </button>
          </div>

          <div class="col-12" *ngIf="!user_id">
            <button
              class="col-12 button-positive"
              mat-button
              color="primary"
              onClick="window.open('https://dashboard.spicaengine.com/login')"
            >
              Login to add cart
            </button>
          </div>

          <div class="col-12" *ngIf="reviews">
            <div *ngIf="reviews.length > 0">
              <user-comment
                class="featured-comment"
                [date]="reviews[0]['date']"
                [score]="reviews[0]['score']"
                [text]="reviews[0]['text']"
                [user_name]="reviews[0].user.author['developer_name']"
              ></user-comment>
            </div>
          </div>

          <div class="col-12 details">
            <div class="label">
              <span>License</span>
              <span>{{ asset.licence }}</span>
            </div>
            <div class="label">
              <span>Latest Version</span>
              <span>{{ asset.latest_version }}</span>
            </div>
            <div class="label">
              <span>Latest release date</span>
              <span>{{ asset.latest_release_date | date }}</span>
            </div>
            <div class="label">
              <span>Support</span>
              <span>{{ asset.support }}</span>
            </div>
          </div>
          <div class="col-12 more-assets">
            <div class="label">
              <span class="title">You might also like</span>
              <a [routerLink]="['/item-list']" class="link">see more</a>
            </div>

            <div class="row more-assets-item" *ngIf="subAssets">
              <div class="col-12" *ngFor="let asset of subAssets">
                <item-single
                  [images]="asset['images']"
                  [name]="asset['name']"
                  [price]="asset['price']"
                  [sales]="asset['sales']"
                  [user_id]="asset['user']"
                  [asset_id]="asset['_id']"
                  [integrated_count]="asset['integrated_count']"
                  [author]="asset.user.author.developer_name"
                ></item-single>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
