import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { debounceTime, take } from "rxjs/operators";
import { AuthService } from "./services/auth-service/auth.service";
import {
  Asset,
  Category,
  IntegratingAssetInformation,
  Tag,
  User,
} from "./interfaces/data";
import { AssetService } from "./services/api-service/asset-service/asset.service";
import { TagService } from "./services/api-service/tag-service/tag.service";
import { Subscription } from "rxjs";
import { UploadAssetSpinnerService } from "./services/ui-service/upload-asset-spinner/upload-asset-spinner.service";
import { IntegrateAssetStepperService } from "./services/ui-service/integrate-asset-stepper/integrate-asset-stepper.service";
@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
  @ViewChild("input") input;

  title = "assetstore";
  loginLink =
    "https://dashboard.spicaengine.com/login?callback=" + location.href;

  logoutLink =
    "https://dashboard.spicaengine.com/login?callback=" +
    location.href +
    "/&operation=logOut";

  selectedCategory;
  selectedSubCategory;

  categoriesArray: Category[] = [];
  subCategoriesArray: Category[] = [];
  searchText = "";
  searchAssets: Asset[] = [];
  searchDevelopers: User[] = [];

  token;
  user_id;
  auth_loading: boolean = false;

  showSubcategories: boolean;

  tags: Tag[];
  navigating_asset: boolean = false;
  search_loading: boolean = false;

  page_changed: boolean = false;

  asset_integrate_loading: boolean = false;
  asset_integrate_loading_subscription: Subscription;

  asset_integration_step_message: IntegratingAssetInformation;
  asset_integration_step_message_subscription: Subscription;

  constructor(
    private assetService: AssetService,
    private router: Router,
    private authService: AuthService,
    private tagService: TagService,
    private uploadAssetSpinnerService: UploadAssetSpinnerService,
    private integrateAssetStepperService: IntegrateAssetStepperService,
    private cdRef: ChangeDetectorRef
  ) {
    this.user_id = localStorage.getItem("user_id");
  }

  //for search bar
  ngAfterViewInit() {
    this.input.update.pipe(debounceTime(200)).subscribe((value) => {
      if (this.searchText && this.searchText?.length > 0) {
        this.startSearchLoading();
        this.searchAssetsWithName(this.searchText);
        // this.getSearchedDevelopers(this.searchText);
      } else {
        this.searchAssets = [];
        this.searchDevelopers = [];
      }
    });
  }

  ngOnInit() {
    this.init();
  }

  async init() {
    this.asset_integrate_loading_subscription = this.uploadAssetSpinnerService
      .getSpinner()
      .subscribe((state) => {
        this.asset_integrate_loading = state;
        this.cdRef.detectChanges();
      });

    this.asset_integration_step_message_subscription =
      this.integrateAssetStepperService.getStepper().subscribe((message) => {
        this.asset_integration_step_message = message;

        this.cdRef.detectChanges();
      });

    this.tags = await this.tagService.getTags();
  }

  ngOnDestroy(): void {
    this.asset_integrate_loading_subscription.unsubscribe();
    this.asset_integration_step_message_subscription.unsubscribe();
  }

  searchAssetsWithName(searchText: string) {
    /*
    page_changed is for: when search text have been written and
    fastly clicked to enter, then this function executed late, then after page changed
    it still shows the searched assets in search section
    */

    this.assetService
      .searchAssetsWithName(this.searchText, 3)
      .toPromise()
      .then((data) => {
        if (this.page_changed == false) {
          this.searchAssets = data;
        } else {
          this.page_changed = false;
        }

        this.stopSearchLoading();
      });
  }

  navigateItemList() {
    if (this.navigating_asset == false) {
      this.page_changed = true;
      this.router.navigate([`item-list`], {
        queryParams: {
          search: this.searchText,
        },
      });
      this.cleanSearchContent();
    } else {
      this.navigating_asset = false;
    }
  }

  navigateItemDetails(asset_id) {
    this.navigating_asset = true;
    this.page_changed = true;
    this.router.navigate([`item-detail/${asset_id}`]);
    this.cleanSearchContent();
  }

  onLoad(event) {
    const iframe = event.path[0];

    window.addEventListener("message", ({ origin, data }) => {
      if (origin != window.origin) {
        const { key, authorization } = data;

        this.token = authorization;

        const apiKey = key;

        //if new token is different from previous one
        if (this.token != this.authService.get_hq_token()) {
          this.startAuthLoading();
          this.token == null
            ? this.authService.deleteLocalStorage()
            : this.authService.set_hq_token(this.token);

          this.authService.user_onchange.pipe(take(1)).subscribe((user_id) => {
            this.user_id = user_id;
            this.stopAuthLoading();
          });
        }
      }
    });
    iframe.contentWindow.postMessage(
      "exchange",
      "https://hq.spicaengine.com/api"
    );
  }

  startAuthLoading() {
    this.auth_loading = true;
  }

  stopAuthLoading() {
    this.auth_loading = false;
  }

  startSearchLoading() {
    this.search_loading = true;
  }

  stopSearchLoading() {
    this.search_loading = false;
  }

  cleanSearchContent() {
    this.searchText = "";
    this.searchAssets = [];
    this.stopSearchLoading();
  }
}
