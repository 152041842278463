import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "user-comment",
  templateUrl: "./user-comment.component.html",
  styleUrls: ["./user-comment.component.scss"],
})
export class UserCommentComponent implements OnInit {
  @Input() date: string;
  @Input() score: number;
  @Input() text: string;
  @Input() user_name: string;

  constructor() {}

  ngOnInit(): void {}
}
