<div class="header-background">
  <mat-toolbar>
    <mat-toolbar-row>
      <div class="container">
        <a [routerLink]="['']">Assetstore</a>
        <div class="container">
          <span class="ex-spacer"></span>
          <input
            matTooltip="Search for Asset"
            matInput
            #input="ngModel"
            [(ngModel)]="searchText"
            placeholder="Search for assets"
            [matAutocomplete]="auto"
            (keyup.enter)="navigateItemList()"
          />
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              value="{{ searchText }}"
              (onSelectionChange)="navigateItemList()"
              (click)="navigateItemList()"
              *ngIf="searchText?.length > 0"
            >
              <div class="custom-search">
                <span>{{ searchText }}</span>
                <mat-icon *ngIf="!search_loading"> search </mat-icon>
                <mat-icon *ngIf="search_loading">
                  <mat-spinner diameter="15"></mat-spinner>
                </mat-icon>
              </div>
            </mat-option>

            <div *ngIf="searchAssets.length > 0">
              <mat-option
                *ngFor="let searchAsset of searchAssets"
                (onSelectionChange)="navigateItemDetails(searchAsset._id)"
              >
                <span
                  ><b>{{ searchAsset.name }}</b></span
                >
              </mat-option>
            </div>

            <!-- <div class="container" *ngIf="searchDevelopers.length > 0">
              <div style="text-align: center; color: blue">Developers</div>
              <hr class="solid" />
              <mat-option
                *ngFor="let searchDeveloper of searchDevelopers"
                [value]="searchDeveloper.author.developer_name"
                [routerLink]="['/developer', searchDeveloper._id]"
              >
                <span
                  ><b>{{ searchDeveloper.author.developer_name }}</b></span
                >
              </mat-option>
            </div> -->
          </mat-autocomplete>

          <div *ngIf="!auth_loading">
            <div *ngIf="user_id">
              <button mat-icon-button [routerLink]="['/cart']">
                <mat-icon matTooltip="Cart">shopping_cart</mat-icon>
              </button>
              <button
                mat-icon-button
                [matMenuTriggerFor]="appMenu"
                matTooltip="Account"
              >
                <mat-icon>person</mat-icon>
              </button>
              <mat-menu #appMenu="matMenu">
                <button mat-menu-item [routerLink]="['/author']">
                  Author Page
                </button>
                <button mat-menu-item [routerLink]="['/upload']">
                  Upload An Asset
                </button>
                <button mat-menu-item [routerLink]="['/assets']">
                  My Assets
                </button>
                <a
                  href="https://dashboard.spicaengine.com/profile"
                  target="_blank"
                  ><button mat-menu-item>Settings</button></a
                >
                <mat-divider></mat-divider>
                <a
                  href="https://dashboard.spicaengine.com/terms"
                  target="_blank"
                  ><button mat-menu-item>Terms Of Service</button></a
                >
                <a
                  href="https://dashboard.spicaengine.com/terms"
                  target="_blank"
                  ><button mat-menu-item>FAQ</button></a
                >

                <mat-divider></mat-divider>
                <a href="{{ logoutLink }}"
                  ><button mat-menu-item>Sign out</button></a
                >
              </mat-menu>
            </div>

            <div *ngIf="!user_id">
              <button mat-icon-button>
                <a href="{{ loginLink }}">
                  <mat-icon>person_outline</mat-icon>
                </a>
              </button>
            </div>
          </div>

          <div *ngIf="auth_loading" class="spinner">
            <mat-spinner [diameter]="25"></mat-spinner>
          </div>
        </div>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>
</div>
<router-outlet></router-outlet>
<mat-toolbar class="footer" color="primary"> </mat-toolbar>

<div *ngIf="asset_integration_step_message?.state != undefined">
  <asset-integration-dialog
    *ngIf="asset_integrate_loading"
    [step]="asset_integration_step_message.step"
    [asset_integrate_successful]="
      asset_integration_step_message.state == 'success'
    "
    [asset_integrate_error]="asset_integration_step_message.state == 'error'"
  >
  </asset-integration-dialog>
</div>

<iframe
  src="https://hq.spicaengine.com/api/fn-execute/v1/state"
  (load)="onLoad($event)"
  style="display: none"
></iframe>
