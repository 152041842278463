import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, merge, forkJoin, Observable } from "rxjs";
import { switchMap, tap } from "rxjs/operators";
import { Asset, Filter } from "src/app/interfaces/data";
import { AssetService } from "src/app/services/api-service/asset-service/asset.service";

@Component({
  selector: "app-asset-list",
  templateUrl: "./asset-list.component.html",
  styleUrls: ["./asset-list.component.scss"],
})
export class AssetListComponent implements OnInit {
  public filter: Filter = {};

  refresh = new Subject<void>();

  selected_category: string = "";
  selected_subcategory: string = "";
  allAssets: Asset[] = [];
  thumbnail_image: string;

  searched_text: string = "";
  tags: string = "a";
  asset_limit: number = 100;
  asset_loading: boolean = true;

  assets_with_tags: Asset[] = [];
  assets_with_name: Asset[] = [];

  searchAssetObservable: Observable<any>;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private assetService: AssetService
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      this.searched_text = params["search"] ? params["search"] : "";
      this.tags = params["tags"] ? params["tags"] : "";
      this.getAssets();
    });
  }

  getAssets() {
    this.cleanAssets();
    merge(this.route.params, this.refresh)
      .pipe(
        tap(() => {
          this.startAssetLoading();
        }),
        switchMap(() => {
          return this.searched_text.length > 0
            ? this.assetService
                .searchAssetsWithName(
                  this.searched_text,
                  this.asset_limit,
                  this.filter
                )
                .toPromise()
                .then((data) => {
                  this.assets_with_name = data;
                })
            : Promise.resolve([]);
        }),
        switchMap(() => {
          return this.assetService
            .searchAssetsWithTags(
              this.getArrayOfSearchTags(),
              this.asset_limit,
              this.filter
            )
            .toPromise()
            .then((data) => {
              this.assets_with_tags = data;
            });
        }),
        tap(() => {
          this.combineAssets(this.assets_with_tags, this.assets_with_name);
        })
      )
      .subscribe();
  }

  combineAssets(asset_with_tags, asset_with_name) {
    asset_with_name.forEach((element) => {
      this.allAssets.push(element);
    });

    asset_with_tags.forEach((element) => {
      if (!this.allAssets.some((assets) => assets._id === element._id)) {
        this.allAssets.push(element);
      }
    });
    this.stopAssetLoading();
  }

  getArrayOfSearchTags() {
    if (this.tags.length > 0) {
      return this.tags.split("\\");
    } else if (this.searched_text) {
      return this.searched_text.split(" ");
    } else {
      return [];
    }
  }

  filterWithTags(event) {
    if (event) {
      this.navigateWithTags(this.addTagsToList(event.tag));
    }
  }

  navigateWithTags(tag) {
    this.router.navigate([`item-list`], {
      queryParams: {
        search: this.searched_text,
        tags: tag,
      },
    });
  }

  addTagsToList(tag) {
    if (this.tags.split("\\").includes(tag)) {
      return this.tags;
    } else if (this.tags.length > 0) {
      return this.tags + "\\" + tag;
    } else {
      return tag;
    }
  }

  removeTagsFromList(tag) {
    this.tags = this.tags.replace("\\" + tag, "");
    this.tags = this.tags.replace(tag + "\\", "");
    this.tags = this.tags.replace(tag, "");
    this.navigateWithTags(this.tags);
  }

  setFilter(filter) {
    this.allAssets = [];
    this.filter = filter;
    this.refresh.next();
  }

  cleanAssets() {
    this.allAssets = [];
  }

  startAssetLoading() {
    this.asset_loading = true;
  }

  stopAssetLoading() {
    this.asset_loading = false;
  }
}
