import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "categories",
})
export class CategoriesPipe implements PipeTransform {
  transform(allList: any): any {
    return allList.filter((category) => category.parent_category == null);
  }
}
