import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Asset, Filter } from "src/app/interfaces/data";
import { environment } from "src/environments/environment";
import { ApiService } from "../api.service";
import { UrlService } from "../url-service/url.service";

@Injectable({
  providedIn: "root",
})
export class AssetService {
  constructor(private urlService: UrlService, private apiService: ApiService) {}

  //GET FUNCTIONS
  getSimilarAssets(asset_id: String, limit: number) {
    const url = this.urlService.bucketUrl(environment.bucket.assets);

    const filter: Filter = {
      approved: true,
      _id: {
        $ne: asset_id,
      },
    };

    const params = {
      filter: JSON.stringify(filter),
      relation: "true",
      limit: `${limit}`,
    };

    return this.apiService.get<Asset[]>(url, params);
  }

  getFeaturedAssets(limit?: number) {
    const url = this.urlService.bucketUrl(environment.bucket.assets);

    const filter: Filter = {
      approved: true,
    };

    const params = {
      filter: JSON.stringify(filter),
      relation: "true",
      sort: '{"_id":-1}',
      limit: "2",
    };

    return this.apiService.get<Asset[]>(url, params);
  }

  getAssets(
    category?: String,
    subCategory?: String,
    filter?: Filter
  ): Observable<Asset[]> {
    let filterJson: Filter = {
      approved: true,
    };
    let max_price = filter ? filter.max_price : "";
    let publish_date = filter ? filter.publish_date : "";
    let publisher = filter ? filter.publisher : "";
    //in all cases we will have categories

    if (category) {
      filterJson["category.link"] = category;
    }

    if (subCategory) {
      filterJson["subcategory.link"] = subCategory;
    }

    if (max_price > 0 && max_price) {
      max_price = max_price + 0.0000001;
      filterJson.price = { $lt: max_price };
    }

    if (publish_date != "" && publish_date) {
      filterJson.latest_release_date = { $gte: `Date(${publish_date})` };
    }

    if (publisher != "" && publisher) {
      filterJson["user.author.developer_name"] = {
        $regex: `${publisher}`,
        $options: "si",
      };
    }
    const url = this.urlService.bucketUrl(environment.bucket.assets);

    const params = {
      relation: "true",
      filter: JSON.stringify(filterJson),
    };

    return this.apiService.get<Asset[]>(url, params);
  }

  getSingleAsset(asset_id: string) {
    const url = this.urlService.bucketUrl(environment.bucket.assets);

    const params = {
      filter: JSON.stringify({
        _id: asset_id,
        approved: true,
      }),
      relation: "true",
    };

    return this.apiService.get<Asset[]>(url, params);
  }

  getAssetofAuthor(user_id: string) {
    const url = this.urlService.bucketUrl(environment.bucket.assets);

    const params = {
      filter: JSON.stringify({
        "user._id": user_id,
        approved: true,
      }),
      relation: "true",
    };

    return this.apiService.get<Asset[]>(url, params);
  }

  getOwnAssets(user_id: string) {
    const url = this.urlService.bucketUrl(environment.bucket.assets);

    const params = {
      filter: JSON.stringify({
        "user._id": user_id,
      }),
      relation: "true",
    };

    return this.apiService.get<Asset[]>(url, params);
  }

  searchAssetsWithName(
    searchText: string,
    limit: number,
    filter?: Filter
  ): Observable<Asset[]> {
    let filterJson: Filter = {
      approved: true,
    };

    if (filter) {
      if (filter.max_price > 0) {
        filterJson.price = { $lt: filter.max_price + 0.0000001 };
      }
      if (filter.publish_date) {
        filterJson.latest_release_date = {
          $gte: `Date(${filter.publish_date})`,
        };
      }
      if (filter.publisher) {
        filterJson["user.author.developer_name"] = {
          $regex: `${filter.publisher}`,
          $options: "si",
        };
      }
    }

    filterJson["name"] = {
      $regex: `${searchText}`,
      $options: "si",
    };

    const url = this.urlService.bucketUrl(environment.bucket.assets);

    const params = {
      relation: true,
      filter: JSON.stringify(filterJson),
      limit: limit,
    };

    return this.apiService.get<Asset[]>(url, params);
  }

  searchAssetsWithTags(
    tags: string[],
    limit: number,
    filter?: Filter
  ): Observable<Asset[]> {
    // tag will be array
    let tag_regex = "";

    let filterJson: Filter = {
      approved: true,
    };

    if (filter) {
      if (filter.max_price > 0) {
        filterJson.price = { $lt: filter.max_price + 0.0000001 };
      }
      if (filter.publish_date) {
        filterJson.latest_release_date = {
          $gte: `Date(${filter.publish_date})`,
        };
      }
      if (filter.publisher) {
        filterJson["user.author.developer_name"] = {
          $regex: `${filter.publisher}`,
          $options: "si",
        };
      }
    }

    // add tag filter here
    if (tags.length > 0) {
      tags.forEach((tag) => {
        tag_regex += tag + "|";
      });

      tag_regex = tag_regex.slice(0, -1);
      filterJson["tags.tag"] = {
        $regex: tag_regex,
      };
      // {"tags.tag":{"$regex":"5|7","$options":"si"}}
    }

    const url = this.urlService.bucketUrl(environment.bucket.assets);

    const params = {
      relation: true,
      filter: JSON.stringify(filterJson),
      limit: limit,
    };

    return this.apiService.get<Asset[]>(url, params);
  }

  //POST FUNCTIONS
  uploadAsset(asset: Asset) {
    const url = this.urlService.bucketUrl(environment.bucket.assets);

    return this.apiService.post<any>(url, asset);
  }

  integrateAsset(market, server_id, reinstall) {
    const url = this.urlService.functionUrl(environment.function.integrate);

    return this.apiService.post<any>(url, { market, server_id, reinstall });
  }

  //UPDATE FUNCTIONS
  updateAsset(asset) {
    const url = this.urlService.bucketUrlWithId(
      environment.bucket.assets,
      asset._id
    );

    return this.apiService.put(url, asset);
  }

  deleteAsset(asset_id: string) {
    const url = this.urlService.bucketUrlWithId(
      environment.bucket.assets,
      asset_id
    );

    return this.apiService.delete(url);
  }
}
