import { Component, OnInit } from "@angular/core";
import { Subject, forkJoin } from "rxjs";
import { Market } from "src/app/interfaces/data";
import { MarketService } from "src/app/services/api-service/market-service/market.service";
import { AuthService } from "src/app/services/auth-service/auth.service";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
})
export class CartComponent implements OnInit {
  refresh = new Subject<void>();

  user_id: string;
  markets: Market = [];
  isProcessing: boolean;

  constructor(
    private marketService: MarketService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.isProcessing = true;
    this.user_id = this.authService.get_user_id();
    this.getAssets();
  }

  deleteFromCart(market_id: string) {
    this.isProcessing = true;

    this.marketService
      .deleteAssetFromCart(market_id)
      .toPromise()
      .then((data) => {
        this.getAssets();
        this.isProcessing = true;
      });
  }

  getAssets() {
    //get assets from that user added to card
    this.marketService
      .getUserCartAssets(this.user_id)
      .toPromise()
      .then((data) => {
        this.markets = data;
        this.isProcessing = false;
      });
  }

  checkout() {
    this.isProcessing = true;

    forkJoin(this.markets.map((market) => this.marketService.buyAsset(market)))
      .toPromise()
      .then((data) => {
        this.getAssets();
      });
  }

  calculateTotal() {
    let total = 0;

    for (let market in this.markets) {
      total = total + this.markets[market].asset.price;
    }
    return total;
  }
}
