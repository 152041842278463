import { Component, OnInit, Input } from "@angular/core";

@Component({
  selector: "generic-rating",
  templateUrl: "./generic-rating.component.html",
  styleUrls: ["./generic-rating.component.scss"],
})
export class GenericRatingComponent implements OnInit {
  @Input() rating: number;
  @Input() ratingCount: number;
  constructor() {}

  ngOnInit(): void {}
}
