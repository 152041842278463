import { EventEmitter } from "@angular/core";
import { Component, OnInit, Input, Output } from "@angular/core";
import { Tag, User } from "src/app/interfaces/data";
import { IntegrationService } from "src/app/services/api-service/integration-service/integration.service";

@Component({
  selector: "item-single",
  templateUrl: "./item-single.component.html",
  styleUrls: ["./item-single.component.scss"],
})
export class ItemSingleComponent implements OnInit {
  @Input() images: any[];
  @Input() name: string;
  @Input() asset_id: string;
  @Input() price: number;
  @Input() author: string;
  @Input() currency: string = "USD";
  @Input() user_id: string;
  @Input() sales: number;
  @Input() horizontal: boolean = false;
  @Input() rating_count: number;
  @Input() rating_average: number;
  @Input() integrated_count: number = 0;
  @Input() tags: Tag[];
  @Input() show_integrate: boolean = true;
  @Input() show_tags: boolean = false;

  @Output() event = new EventEmitter<Tag>();

  thumbnail_image: string;
  user: User;

  constructor(private integrationService: IntegrationService) {}

  ngOnInit(): void {
    this.thumbnail_image = this.images[0];

    if (this.integrated_count == undefined && this.integrated_count == null) {
      this.integrated_count = 0;
    }

    this.price > 0 ? (this.show_integrate = false) : undefined;
  }

  tagSelected(tag: Tag) {
    this.event.emit(tag);
  }

  openDialog() {
    this.integrationService.openIntegrationAssetDialog(this.asset_id);
  }
}
